import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { convertQueryParamsIntoObject } from '../constants/Helpers';
//actions
import { setWebSocketConnection } from '../store/app/AppActions';
//selectors
import { getNewNotification, getIsNewNotification } from '../store/app/AppSelectors';
//api
import AppService from '../services/AppService';
import SectionLoader from '../components/shared/SectionLoader';

const GreetingsPage = () => {
  const newInvitee = useSelector((state) => getNewNotification({ state }));
  const isNewNotification = useSelector((state) => getIsNewNotification({ state }));
  const [occasionName, setOccasionName] = useState('');
  const [standImgUrl, setStandImgUrl] = useState('');
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  let timer;

  const fetchOccasionById = async (occasionId) => {
    setIsLoaderOpen(true);
    try {
      const res = await AppService.getOccasionById(occasionId);
      setStandImgUrl(res?.data?.standDesignUrl);
      setOccasionName(res?.data?.name);
      setIsLoaderOpen(false);
    } catch (err) {
      setIsLoaderOpen(false);
      toast.error(err?.response?.data?.message ?? 'فشل تفاصيل المناسبة');
    }
  };

  useEffect(() => {
    if (location?.search) {
      const queryObj = convertQueryParamsIntoObject(location?.search);
      fetchOccasionById(queryObj?.occasion);
    }
  }, [location?.search]);

  useEffect(() => {
    if (location?.search) {
      const queryObj = convertQueryParamsIntoObject(location?.search);
      dispatch(setWebSocketConnection(queryObj?.occasion));
    }
  }, []);

  useEffect(() => {
    if (isNewNotification) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        dispatch({
          type: 'SET_IS_NEW_NOTIFICATION_FALSE',
        });
      }, 120000);
    }
  }, [isNewNotification]);

  return (
    <>
      {isLoaderOpen ? (
        <SectionLoader />
      ) : (
        <>
          {isNewNotification ? (
            <div className="greetings-container">
              <img
                className="invitee-background-img"
                src="https://floward.imgix.net/web/files/tickets/60b8499c-6567-4bef-a6a9-f16cee34e9c8.jpg"
              />
              <div className="greetings-page-wrapper">
                <div className="greetings">حياكم اللّه</div>
                <div className="family-name">{occasionName}</div>
                <div className="invitee-name">{newInvitee?.name}</div>
              </div>
            </div>
          ) : (
            <div>
              {standImgUrl ? (
                <img className="stand-img" src={standImgUrl} />
              ) : (
                <p style={{ fontSize: 20, textAlight: 'center' }}>لا يوجد تصميم استاند</p>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default GreetingsPage;
