import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
//components
import MapComponent from '../components/home/MapComponent';
//actions
import { setOccasionsList } from '../store/app/AppActions';
//selectors
import { getOccasionsList } from '../store/app/AppSelectors';

const HomePage = () => {
  const occasionsList = useSelector((state) => getOccasionsList({ state }));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setOccasionsList());
  }, []);

  return (
    <div className="page-wrapper">
      <MapComponent occasionsList={occasionsList} />
    </div>
  );
};

export default HomePage;
