import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import AppErrorBoundary from '../shared/AppErrorBoundary';
import CustomSelect from '../shared/CustomSelect';
import { Card, Grid } from '@material-ui/core';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import Button from '@material-ui/core/Button';
import QrReader from 'react-qr-reader2';
import PollingModal from '../modals/PollingModal';
import { flagIcon, convertObjectIntoQueryParams, userType } from '../../constants/Helpers';
//api
import AppService from '../../services/AppService';

const CoversHeader = ({ occasionsList, setIsLoading, fetchScannedList, setOccasion, occasion }) => {
  const [coverNumber, setCoverNumber] = useState('');
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [isScanned, setIsScanned] = useState(false);
  const [errorMessage, setErrorMesaage] = useState('');
  const [scanIcon, setScanIcon] = useState('');
  const [isPollModalOpen, setIsPollModalOpen] = useState(false);
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const handleScanOrder = async (data) => {
    if (data) {
      if (isScanned) {
        return;
      }
      setIsCameraOpen(false);
      setIsScanned(true);
      setCoverNumber(data);
      const dataBody = {
        coverNumber: data,
        occasionId: occasion,
      };
      try {
        await AppService.getCheckOutByCover(dataBody);
        setTimeout(() => {
          fetchScannedList(occasion);
        }, 200);
        setScanIcon('true');
        setErrorMesaage('');
        setCoverNumber('');
        inputRef.current.focus();
      } catch (err) {
        setErrorMesaage(err?.response?.data?.message);
        setScanIcon('false');
        setCoverNumber('');
        inputRef.current.focus();
      }
    }
  };

  const handleError = (err) => {
    console.log(err);
  };

  const handleOccasionChange = async (value) => {
    setOccasion(value?.id);
    setIsLoading(true);
    await fetchScannedList(value?.id);
    setIsLoading(false);
    const queryParams = { occasionId: value?.id };
    const queryString = convertObjectIntoQueryParams(queryParams);
    navigate({
      pathname: '/covers',
      search: queryString,
    });
  };

  const handleManualScan = async (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const dataBody = {
        coverNumber,
        occasionId: occasion,
      };
      try {
        await AppService.getCheckOutByCover(dataBody);
        setTimeout(() => {
          fetchScannedList(occasion);
        }, 200);
        setScanIcon('true');
        setErrorMesaage('');
        setCoverNumber('');
        inputRef.current.focus();
      } catch (err) {
        setErrorMesaage(err?.response?.data?.message);
        setScanIcon('false');
        setCoverNumber('');
        inputRef.current.focus();
      }
    }
  };

  const attendeceList = () => {
    const attendeceList = occasionsList?.filter((el) => el?.parentId === 0);
    if (userType == 1) return attendeceList;
    else {
      return attendeceList?.filter(
        (el) => new Date(el?.date)?.toDateString() === new Date()?.toDateString()
      );
    }
  };

  const handleCoverChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,4}$/.test(value)) {
      setCoverNumber(value);
    }
  };

  return (
    <>
      {isCameraOpen ? (
        <div className="camera-wrapper" style={{ marginTop: 20 }}>
          <QrReader
            delay={500}
            facingMode="environment"
            legacyMode={true}
            style={{
              width: '100%',
              height: '100%',
            }}
            onError={handleError}
            onScan={handleScanOrder}
          />
          <Button
            onClick={() => {
              setIsCameraOpen(false);
            }}
            variant="contained"
            color="secondary"
            className="camera-close-btn"
          >
            قفل
          </Button>
        </div>
      ) : (
        <>
          <Card className="card-wrapper">
            <div className="add-driver-modal">
              <form className="form" style={{ padding: '10px 0' }}>
                <Grid>
                  <Grid item md={4} sm={12} className="input-wrapper select-input">
                    <label className="input-label">اسم المناسبة</label>
                    <AppErrorBoundary>
                      <CustomSelect
                        listData={attendeceList()}
                        // listData={ occasionsList?.filter((el) => el?.parentId === 0)}
                        handleChange={(value) => handleOccasionChange(value)}
                        placeholder="اختر اسم المناسبة"
                        selectedValue={occasion}
                        optionValue="id"
                        optionName="name"
                        disableClearIcon
                      />
                    </AppErrorBoundary>
                  </Grid>
                  {occasion && (
                    <div style={{ display: 'flex', justifyContent: 'start' }}>
                      <Grid item md={2} sm={12} className="input-wrapper select-input">
                        <Button
                          color="primary"
                          style={{ backgroundColor: '#006782' }}
                          variant="contained"
                          size="small"
                          onClick={() => setIsPollModalOpen(true)}
                        >
                          الاستبيان الخاص بالمناسبة
                        </Button>
                      </Grid>
                    </div>
                  )}
                </Grid>
              </form>
            </div>
          </Card>
          <Card className="card-wrapper">
            <div className="add-driver-modal">
              <form className="form" style={{ padding: '10px 0' }}>
                <Grid
                  container
                  className="input-wrapper select-input"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Grid item md={4} sm={12} style={{ display: 'flex', flexDirection: 'column' }}>
                    <label htmlFor="driver-type" className="input-label">
                      تسجيل الخروج
                    </label>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        id="driver-name"
                        onChange={handleCoverChange}
                        onKeyPress={handleManualScan}
                        className="input"
                        type="number"
                        value={coverNumber}
                        placeholder=" رقم الكڤر"
                        disabled={!occasion}
                        ref={inputRef}
                      />
                      {scanIcon && (
                        <img
                          src={scanIcon === 'true' ? flagIcon.trueIcon : flagIcon.falseIcon}
                          style={{ width: 45, padding: 5 }}
                        />
                      )}
                      {errorMessage && <p style={{ color: 'red', fontSize: 15 }}>{errorMessage}</p>}
                    </div>
                  </Grid>
                  <Grid style={{ textAlign: 'end' }} item md={3} sm={12}>
                    <Button
                      style={{ backgroundColor: '#006782', marginTop: 30 }}
                      onClick={() => {
                        setIsCameraOpen(true);
                        setIsScanned(false);
                      }}
                      variant="contained"
                      color="primary"
                      endIcon={<CameraAltIcon style={{ marginRight: 8 }} />}
                      size="small"
                      disabled={!occasion}
                    >
                      مسح الباركود
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Card>
        </>
      )}
      {isPollModalOpen && (
        <PollingModal
          isPollModalOpen={isPollModalOpen}
          setIsPollModalOpen={setIsPollModalOpen}
          occasionId={occasion}
        />
      )}
    </>
  );
};

export default CoversHeader;
