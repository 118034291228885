import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  Typography,
  DialogContent,
  DialogActions,
  IconButton,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { handleRealoadPage } from '../../constants/Helpers';
import AppService from '../../services/AppService';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(3),
  },
  closeButton: {
    position: 'absolute',
    // right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          style={{ left: 8 }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const EditInviteeModal = ({
  selectedInv,
  isPopupOpen,
  setIsPopUpOpen,
  occasionId,
  fetchOccasionById,
  setSelectedInv,
  isLivePage,
}) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [count, setCount] = useState('');
  const [isAttended, setIsAttended] = useState(true);
  const [disableBtn, setDisableBtn] = useState(false);

  useEffect(() => {
    if (selectedInv && Object.keys(selectedInv).length > 0) {
      setName(selectedInv?.name ?? '');
      setPhone(selectedInv?.phone ?? '');
      setCount(isLivePage ? selectedInv?.qrCodes?.length : selectedInv?.count);
      setIsAttended(selectedInv?.isAttended ?? '');
    }
  }, [selectedInv]);

  const handleSubmit = async () => {
    let dataBody = {
      name,
      phone: phone?.replace(/\D/g, ''),
      count,
      isAttended,
      occasionId,
    };
    setDisableBtn(true);
    try {
      const res = selectedInv
        ? await AppService.editInvitee(dataBody, selectedInv?.id)
        : await AppService.addNewInvitee(dataBody);
      if (isLivePage) {
        setTimeout(() => {
          handleRealoadPage();
        }, 500);
      } else {
        toast.success(res?.data?.message ?? 'تم التعديل بنجاح');
        setTimeout(() => {
          fetchOccasionById(occasionId);
        }, 500);
      }
      setIsPopUpOpen(false);
    } catch (e) {
      toast.error(e?.response?.data?.message ?? 'فشل في التعديل');
    }
  };

  useEffect(() => {
    return () => {
      setSelectedInv('');
    };
  }, []);

  return (
    <div>
      <Dialog
        onClose={() => setIsPopUpOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={isPopupOpen}
        style={{ maxHeight: 750, direction: 'rtl' }}
        className="add-driver-modal"
      >
        <DialogTitle id="customized-dialog-title" onClose={() => setIsPopUpOpen(false)}>
          {selectedInv ? 'تعديل المدعوه' : 'اضافة مدعو جديد'}
        </DialogTitle>
        <DialogContent dividers>
          <form className="form">
            <div className="input-wrapper">
              <label htmlFor="driver-name" className="input-label">
                الاسم <span className="red-color"> *</span>
              </label>
              <input
                id="driver-name"
                onChange={(e) => setName(e.target.value)}
                placeholder="اسم المدعو"
                className="input"
                type="text"
                value={name}
              />
            </div>
            <div className="input-wrapper">
              <label htmlFor="driver-phone" className="input-label">
                رقم التليفون <span className="red-color"> *</span>{' '}
              </label>
              <input
                id="driver-phone"
                onChange={(e) => setPhone(e.target.value)}
                placeholder="برجاء ادخال الارقام بالانجليزية"
                className="input"
                value={phone}
                disabled={isLivePage && selectedInv}
              />
            </div>
            <div className="input-wrapper">
              <label htmlFor="driver-phone" className="input-label">
                عدد الدعوات <span className="red-color"> *</span>
              </label>
              <input
                id="driver-phone"
                onChange={(e) => setCount(e.target.value)}
                placeholder="برجاء ادخال الارقام بالانجليزية"
                className="input"
                type="number"
                value={count}
              />
            </div>
          </form>
        </DialogContent>
        <DialogActions style={{ padding: '8px 24px' }}>
          <Button autoFocus onClick={() => setIsPopUpOpen(false)}>
            الغاء
          </Button>
          <Button
            style={{ backgroundColor: '#22af47', color: 'white' }}
            variant="contained"
            onClick={handleSubmit}
            disabled={disableBtn || !name || !phone || !count}
          >
            حفظ
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditInviteeModal;
