import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  Typography,
  DialogContent,
  DialogActions,
  IconButton,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import AppService from '../../services/AppService';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(3),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const CheckInModal = ({
  selectedCover,
  isPopupOpen,
  setIsPopUpOpen,
  occasionId,
  fetchScannedList,
  setSelectedCover,
  isAttendancePage,
  fetchScannedAttendece,
}) => {
  const [coverNumber, setCoverNumber] = useState('');
  const [disableBtn, setDisableBtn] = useState(false);
  const inputRef = useRef(null);

  const handleSubmit = async () => {
    let dataBody = {
      coverNumber,
    };
    setDisableBtn(true);
    try {
      const res = await AppService.getCheckIn(
        dataBody,
        isAttendancePage ? selectedCover?.coverId : selectedCover?.id
      );
      toast.success(res?.data?.message ?? 'تم اضافة رقم الكڤر');
      setTimeout(() => {
        isAttendancePage ? fetchScannedAttendece(occasionId) : fetchScannedList(occasionId);
      }, 200);

      setIsPopUpOpen(false);
      setDisableBtn(false);
    } catch (e) {
      toast.error(e?.response?.data?.message ?? 'فشل في اضافة رقم الكڤر');
      setDisableBtn(false);
    }
  };

  const handleCoverChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,4}$/.test(value)) {
      setCoverNumber(value);
    }
  };

  useEffect(() => {
    return () => {
      setSelectedCover('');
    };
  }, []);

  const handleEnterCoverManual = async (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      let dataBody = {
        coverNumber,
      };
      setDisableBtn(true);
      try {
        const res = await AppService.getCheckIn(
          dataBody,
          isAttendancePage ? selectedCover?.coverId : selectedCover?.id
        );
        toast.success(res?.data?.message ?? 'تم اضافة رقم الكڤر');
        if (!isAttendancePage) {
          setTimeout(() => {
            fetchScannedList(occasionId);
          }, 200);
        }
        setIsPopUpOpen(false);
        setDisableBtn(false);
      } catch (e) {
        toast.error(e?.response?.data?.message ?? 'فشل في اضافة رقم الكڤر');
        setDisableBtn(false);
      }
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 100);

    return () => clearTimeout(timeoutId); // Clean up timeout on unmount
  }, []);

  return (
    <div className="">
      <Dialog
        onClose={() => setIsPopUpOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={isPopupOpen}
        style={{ maxHeight: 750 }}
        className="add-driver-modal"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => setIsPopUpOpen(false)}
          style={{ padding: 15 }}
        >
          ادخل رقم الكڤر
        </DialogTitle>
        <DialogContent dividers>
          <form className="form" style={{ padding: '15px 0px' }}>
            <div className="input-wrapper">
              <label htmlFor="driver-phone" className="input-label" style={{ textAlign: 'end' }}>
                تسجيل دخول {selectedCover?.invitee?.name}
              </label>
              <input
                id="driver-phone"
                style={{ textAlign: 'end' }}
                onChange={handleCoverChange}
                onKeyPress={handleEnterCoverManual}
                placeholder="برجاء ادخال الارقام بالانجليزية"
                className="input"
                type="number"
                value={coverNumber}
                ref={inputRef}
              />
            </div>
          </form>
        </DialogContent>
        <DialogActions style={{ padding: '8px 24px' }}>
          <Button autoFocus onClick={() => setIsPopUpOpen(false)}>
            الغاء
          </Button>
          <Button
            style={{ backgroundColor: '#22af47', color: 'white' }}
            variant="contained"
            onClick={handleSubmit}
            disabled={disableBtn || !coverNumber}
          >
            حفظ
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CheckInModal;
