import React, { Fragment } from 'react';

const WindowInfoComponent = ({ selectedOccasion, navigate }) => {
  const openGoogleMaps = (e) => {
    e.stopPropagation();
    window.open(
      `https://www.google.com/maps/?q=${selectedOccasion?.latitude},${selectedOccasion?.longitude}`
    );
  };

  return (
    <div className="marker-info-window">
      <Fragment>
        <h3 onClick={() => navigate(`/edit-occasion/${selectedOccasion?.id}`)}>
          المناسبة: <span className="location">{selectedOccasion?.name}</span>
        </h3>
        <h3> التاريخ: {selectedOccasion?.date?.split('T')[0]} </h3>
        <h3 onClick={openGoogleMaps}>
          الموقع:<span className="location">{selectedOccasion?.addressName}</span>
        </h3>
        <h3>عدد الحضور: {selectedOccasion?.actualNumberOfAttendees}</h3>
      </Fragment>
    </div>
  );
};

export default WindowInfoComponent;
