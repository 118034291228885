import { combineReducers } from 'redux';
import app from '../store/app/AppReducer';
import trips from '../store/trips/TripsReducer';
import dispatch from '../store/dispatch/DispatchReducer';

const rootReducer = combineReducers({
  app,
  trips,
  dispatch,
});

export default rootReducer;
