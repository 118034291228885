import { useSelector } from 'react-redux';
import { useLocation, Navigate } from 'react-router-dom';
//selectors
import { getIsLoggedIn } from '../../store/app/AppSelectors';

const PublicRouteGuard = ({ restricted, children, redirect }) => {
  const location = useLocation();
  const isLoggedIn = useSelector((state) => getIsLoggedIn({ state }));

  if (redirect) {
    return <Navigate replace to={redirect} />;
  } else if (isLoggedIn && restricted) {
    return <Navigate replace to="/home" state={{ from: location }} />;
  } else {
    return <>{children}</>;
  }
};

export default PublicRouteGuard;
