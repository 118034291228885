import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SectionLoader from '../components/shared/SectionLoader';
import AppErrorBoundary from './../components/shared/AppErrorBoundary';
import { dateFormatDDMMYYYY } from './../constants/Helpers';
import OccasionPoll from '../components/occasionPoll/OccasionPoll';
import EditInviteeModal from '../components/modals/EditInviteeModal';
import { toast } from 'react-toastify';
//material ui
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import MaterialDataGrid from './../components/shared/MaterialDataGrid';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Button } from '@material-ui/core';
//API
import AppService from '../services/AppService';

const OccasionLivePage = () => {
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [occasionData, setOccasionData] = useState({});
  const [clonedOccasionData, setClonedOccasionData] = useState({});
  const [typeValue, setTypeValue] = useState(0);
  const [isPolling, setIsPolling] = useState(false);
  const [pollingData, setPollingData] = useState({});
  const [isPopupOpen, setIsPopUpOpen] = useState(false);
  const [selectedInv, setSelectedInv] = useState('');
  const [disableBtn, setDisableBtn] = useState(false);
  const params = useParams();

  const fetchUserData = async (ownerToken) => {
    setIsLoaderOpen(true);
    try {
      const res = await AppService.getDataByOwnerToken(ownerToken);
      setOccasionData(res?.data);
      setClonedOccasionData(res?.data);
      setIsLoaderOpen(false);
    } catch (err) {
      setIsLoaderOpen(false);
    }
  };

  const fetchPollingData = async (ownerToken) => {
    try {
      const res = await AppService.getPollingData(ownerToken);
      setPollingData(res?.data);
    } catch (err) {}
  };

  useEffect(() => {
    fetchUserData(params?.id);
    fetchPollingData(params?.id);
    // getTotalApologized();
  }, []);

  const handleApologizeInvitee = async (rowData) => {
    const dataBody = {
      accept: false,
    };
    setDisableBtn(true);
    try {
      await AppService.getConfirmAttend(dataBody, rowData?.id);
      setTimeout(() => {
        fetchUserData(params?.id);
      }, 200);
      setDisableBtn(false);
      toast.success(`تم الغاء ${rowData?.name}`);
    } catch (err) {
      toast.error(err?.response?.data?.message ?? 'حدث خطأ');
      setDisableBtn(false);
    }
  };

  const columns = [
    {
      title: 'الاسم',
      field: 'name',
    },
    {
      title: 'عدد الدعوات',
      render: (rowData) => rowData?.qrCodes?.length,
    },
    {
      title: 'الحالة',
      render: (rowData) => (
        <span>
          {!rowData?.isTokenUsed ? 'غير محدد' : rowData?.isRejected ? 'معتذر' : 'تأكيد الحضور'}
        </span>
      ),
    },
    {
      title: ' التليفون',
      field: 'phone',
    },
    {
      title: 'تعديل',
      render: (rowData) => (
        <>
          <Button
            style={{ backgroundColor: '#006782', color: 'white', margin: 3 }}
            variant="contained"
            size="small"
            onClick={() => {
              setIsPopUpOpen(true);
              setSelectedInv(rowData);
            }}
          >
            تعديل
          </Button>
          <Button
            style={{
              backgroundColor: '#F50157',
              color: 'white',
              margin: 3,
              display: rowData?.isRejected ? 'none' : 'block',
            }}
            variant="contained"
            size="small"
            onClick={() => {
              handleApologizeInvitee(rowData);
            }}
            disabled={disableBtn}
          >
            الغاء
          </Button>
        </>
      ),
    },
  ];

  const options = {
    paging: false,
    showTitle: false,
    columnsButton: false,
    sorting: false,
    exportButton: false,
    toolbar: false,
  };

  const handleChangeType = (e, newValue) => {
    setTypeValue(newValue);
    if (newValue === 0) setOccasionData(clonedOccasionData);
    else if (newValue === 1)
      setOccasionData({
        ...clonedOccasionData,
        invitees: clonedOccasionData?.invitees?.filter(
          (el) => el.isRejected === false && el?.isTokenUsed === true
        ),
      });
    else if (newValue === 2)
      setOccasionData({
        ...clonedOccasionData,
        invitees: clonedOccasionData?.invitees?.filter((el) => el.isRejected === true),
      });
    else if (newValue === 3)
      setOccasionData({
        ...clonedOccasionData,
        invitees: clonedOccasionData?.invitees?.filter((el) => !el.isTokenUsed),
      });
  };

  const getAttendeceCount = () => {
    const attendanceEntries =
      clonedOccasionData?.invitees
        ?.filter((el) => el?.isAttended === true)
        ?.flatMap((entry) => entry?.qrCodes) ?? [];
    const totalLength = attendanceEntries?.filter((invitee) => invitee?.isQrCodeUsed)?.length;
    return totalLength;
  };

  const getTotalConfiremed = () => {
    const rejectedEntries = clonedOccasionData?.invitees?.filter(
      (el) => el?.isRejected === false && el?.isTokenUsed === true
    );
    const totalLength = rejectedEntries?.reduce((total, entry) => total + entry.qrCodes.length, 0);
    return totalLength;
  };

  const getTotalApologized = () => {
    const rejectedEntries = clonedOccasionData?.invitees?.filter((el) => el.isRejected === true);
    const totalLength = rejectedEntries?.reduce((total, entry) => total + entry.qrCodes.length, 0);
    return totalLength;
  };

  const getNotSpecified = () => {
    const notSpecifiedUseers = clonedOccasionData?.invitees?.filter((el) => !el.isTokenUsed);
    const totalLength = notSpecifiedUseers?.reduce(
      (total, entry) => total + entry.qrCodes.length,
      0
    );
    return totalLength;
  };

  return (
    <div className="apologize-container">
      {isLoaderOpen ? (
        <SectionLoader />
      ) : (
        <div className="apologize-wrapper">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ alignSelf: 'start', margin: 15, textAlign: 'right' }}>
              <img
                className="header-ops-img"
                src={
                  'https://floward.imgix.net/web/files/tickets/8701f83f-b7f6-4b01-b9ac-38afdce5f521.png'
                }
                style={{ width: 100 }}
              />
            </div>
            <div style={{ alignSelf: 'end', margin: 19 }}>
              <Button
                color="primary"
                style={{ backgroundColor: '#006782', color: 'white', margin: 5 }}
                variant="contained"
                size="small"
                onClick={() => setIsPolling((prev) => !prev)}
              >
                {isPolling ? 'اضغط لمتابعة الحضور' : 'اضغط للاستبيان'}
              </Button>
            </div>
          </div>
          {isPolling ? (
            <OccasionPoll
              pollingData={pollingData}
              ownerToken={params?.id}
              fetchPollingData={fetchPollingData}
              hasCovers={occasionData?.hasCovers}
            />
          ) : (
            <div>
              <p style={{ fontWeight: 'bold', color: '#1E3F66' }}>{occasionData?.name}</p>
              <p style={{ fontWeight: 'bold', color: '#1E3F66' }}>
                {dateFormatDDMMYYYY(occasionData?.date)}
              </p>
              <Button
                style={{ backgroundColor: '#5cb85c', color: 'white', margin: 5 }}
                variant="contained"
                type="submit"
                onClick={() => {
                  setOccasionData({
                    ...clonedOccasionData,
                    invitees: clonedOccasionData?.invitees?.filter((el) => el.isAttended === true),
                  });
                  setTypeValue(null);
                }}
              >
                الحضور : {getAttendeceCount()}
              </Button>
              <Button
                style={{ backgroundColor: '#22af47', color: 'white' }}
                variant="contained"
                onClick={() => setIsPopUpOpen(true)}
              >
                اضافة مدعو جديد
              </Button>
              <Box style={{ overflow: 'auto' }}>
                <Tabs
                  indicatorColor="secondary"
                  textColor="black"
                  value={typeValue}
                  onChange={handleChangeType}
                  centered
                  style={{ overflow: 'auto' }}
                >
                  <Tab
                    label={
                      <>
                        <span style={{ fontWeight: 'bold' }}>اجمالي الدعوات </span>
                        <p style={{ fontWeight: 'bold' }}>
                          {
                            clonedOccasionData?.invitees?.flatMap((entery) => entery?.qrCodes)
                              ?.length
                          }
                        </p>
                      </>
                    }
                  />
                  <Tab
                    label={
                      <>
                        <span style={{ fontWeight: 'bold' }}>تأكيد</span>
                        <p style={{ fontWeight: 'bold' }}>{getTotalConfiremed()}</p>
                      </>
                    }
                  />
                  <Tab
                    label={
                      <>
                        <span style={{ fontWeight: 'bold' }}>معتذر</span>{' '}
                        <p style={{ fontWeight: 'bold' }}>{getTotalApologized()}</p>
                      </>
                    }
                  />
                  <Tab
                    label={
                      <>
                        <span style={{ fontWeight: 'bold' }}>غير محدد</span>{' '}
                        <p style={{ fontWeight: 'bold' }}>{getNotSpecified()}</p>
                      </>
                    }
                  />
                </Tabs>
              </Box>
              <AppErrorBoundary>
                <TableContainer component={Paper}>
                  <MaterialDataGrid
                    data={occasionData?.invitees ?? []}
                    columns={columns}
                    options={options}
                  />
                </TableContainer>
              </AppErrorBoundary>
            </div>
          )}
        </div>
      )}
      {isPopupOpen && (
        <EditInviteeModal
          occasionId={occasionData?.id}
          isPopupOpen={isPopupOpen}
          setIsPopUpOpen={setIsPopUpOpen}
          isLivePage
          selectedInv={selectedInv}
          setSelectedInv={setSelectedInv}
        />
      )}
    </div>
  );
};

export default OccasionLivePage;
