import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AppErrorBoundary from '../shared/AppErrorBoundary';
//components
import AttendanceContent from './AttendanceContent';
import AttendanceHeader from './AttendanceHeader';
//actions
import { setOccasionsList } from '../../store/app/AppActions';
//selectors
import { getOccasionsList } from '../../store/app/AppSelectors';

const AttendanceContainer = () => {
  const occasionsList = useSelector((state) => getOccasionsList({ state }));
  const [scannedList, setScannedList] = useState([]);
  const [isLoading, setIsLoading] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setOccasionsList());
  }, []);

  return (
    <AppErrorBoundary>
      <AttendanceHeader
        setScannedList={setScannedList}
        setIsLoading={setIsLoading}
        occasionsList={occasionsList}
      />
      <AttendanceContent
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        scannedList={scannedList}
        setScannedList={setScannedList}
      />
    </AppErrorBoundary>
  );
};

export default AttendanceContainer;
