import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
//components
import AddOccasionForm from '../components/addOccasion/AddOccasionForm';
import AppErrorBoundary from '../components/shared/AppErrorBoundary';
import SectionLoader from '../components/shared/SectionLoader';
import { toast } from 'react-toastify';
//API
import AppService from '../services/AppService';

const EditOccasionPage = () => {
  const params = useParams();
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [occasionDetails, setOccasionDetails] = useState({});
  const [clonedOccasionDetails, setClonedOccasionDetails] = useState({});

  const fetchOccasionById = async (occasionId) => {
    setIsLoaderOpen(true);
    try {
      const res = await AppService.getOccasionById(occasionId);
      setOccasionDetails(res?.data);
      setClonedOccasionDetails(res?.data);
      setIsLoaderOpen(false);
    } catch (err) {
      setIsLoaderOpen(false);
      toast.error(err?.response?.data?.message ?? 'فشل تفاصيل المناسبة');
    }
  };

  useEffect(() => {
    fetchOccasionById(params?.id);
  }, []);

  return (
    <div className="page-wrapper">
      {isLoaderOpen ? (
        <SectionLoader />
      ) : (
        <AppErrorBoundary>
          <AddOccasionForm
            occasionId={params?.id}
            occasionDetails={occasionDetails}
            setOccasionDetails={setOccasionDetails}
            clonedOccasionDetails={clonedOccasionDetails}
            fetchOccasionById={fetchOccasionById}
          />
        </AppErrorBoundary>
      )}
    </div>
  );
};

export default EditOccasionPage;
