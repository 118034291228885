import { useLocation, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getIsLoggedIn } from '../../store/app/AppSelectors';

const PrivateRouteGuard = ({ children }) => {
  const isLoggedIn = useSelector((state) => getIsLoggedIn({ state }));
  const location = useLocation();

  if (isLoggedIn) {
    return <>{children}</>;
  }
  return <Navigate replace to="/login" state={{ from: location }} />;
};

export default PrivateRouteGuard;
