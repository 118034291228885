import { updateObject } from '../utility';
import jwt_decode from 'jwt-decode';

const initialState = {
  isLeftSideBarOpen: window.screen.width > 790 ? true : false,
  isRightSideBarOpen: true,
  isAssignOrdersModalOpen: false,
  decodedToken: localStorage.getItem('LOGISTICS_TOKEN')
    ? jwt_decode(localStorage.getItem('LOGISTICS_TOKEN'))
    : '',
  opsCenterInfo: '',
  opsId: localStorage.getItem('opsId') ?? '',
  isAddDriverModalOpen: false,
  isOpsModalOpen: false,
  opsCenters: [],
  defaultZoom: 11,
  isMobileSize: window.screen.width < 600,
  isDesktopSize: window.screen.width > 900,
  isDriversListModalOpen: false,
  isCreatePlanModalOpen: false,
  isPlannedOrdersModalOpen: false,
  isBulkUploadDriversModalOpen: false,
  //DAWA
  isLoggedIn: localStorage.getItem('isAuth') ?? false,
  isDarkMode: localStorage.getItem('isDark') === 'true',
  occasionsList: [],
  selectedOccasion: '',
  isLoading: false,
  newNotification: '',
  isNewNotification: false,
  newScannedInvitee: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_IS_LOGGED_IN_TRUE': {
      localStorage.setItem('isAuth', true);
      localStorage.setItem('userType', action?.payload?.type);
      return updateObject(state, { isLoggedIn: true });
    }

    case 'SET_IS_LOGGED_IN_FALSE': {
      localStorage.removeItem('isAuth');
      localStorage.removeItem('userType');
      return updateObject(state, { isLoggedIn: false });
    }

    case 'DECODED_TOKEN': {
      return updateObject(state, {
        decodedToken: jwt_decode(localStorage.getItem('LOGISTICS_TOKEN')),
      });
    }

    case 'SET_CURRENT_OPS': {
      return updateObject(state, {
        opsId: localStorage.getItem('opsId'),
      });
    }

    case 'TOGGLE_DARK_LIGHT_MODE': {
      const isDarkMode = !state.isDarkMode;
      localStorage.setItem('isDark', isDarkMode);
      return updateObject(state, { isDarkMode });
    }
    case 'TOGGLE_LEFT_SIDE_BAR': {
      return updateObject(state, {
        isLeftSideBarOpen: !state.isLeftSideBarOpen,
      });
    }
    case 'TOGGLE_RIGHT_SIDE_BAR': {
      return updateObject(state, {
        isRightSideBarOpen: !state.isRightSideBarOpen,
      });
    }

    case 'TOGGLE_ASSIGN_ORDERS_MODAL_OPEN': {
      return updateObject(state, {
        isAssignOrdersModalOpen: !state.isAssignOrdersModalOpen,
      });
    }

    case 'TOGGLE_ADD_DRIVER_MODAL_OPEN': {
      return updateObject(state, {
        isAddDriverModalOpen: !state.isAddDriverModalOpen,
      });
    }

    case 'SET_OPS_CENTER_INFO': {
      return updateObject(state, {
        opsCenterInfo: action.payload,
      });
    }

    case 'TOGGLE_OPS_MODAL_OPEN': {
      return updateObject(state, {
        isOpsModalOpen: !state.isOpsModalOpen,
      });
    }

    case 'SET_ALL_OPS_CENTERS': {
      return updateObject(state, {
        opsCenters: action.payload,
      });
    }

    case 'SET_INCREASE_DEFAULT_ZOOM': {
      return updateObject(state, {
        defaultZoom: state.defaultZoom + 1,
      });
    }

    case 'SET_DECREASE_DEFAULT_ZOOM': {
      return updateObject(state, {
        defaultZoom: state.defaultZoom - 1,
      });
    }

    case 'SET_RESET_DEFAULT_ZOOM': {
      return updateObject(state, {
        defaultZoom: 13,
      });
    }

    case 'SET_GOOGLE_TOKEN': {
      return updateObject(state, {
        googleToken: localStorage.getItem('GOOGLE_AUTH_TOKEN'),
      });
    }
    case 'TOGGLE_DRIVERS_LIST_MODAL': {
      return updateObject(state, {
        isDriversListModalOpen: !state.isDriversListModalOpen,
      });
    }

    case 'TOGGLE_CREATE_PLAN_MODAL_OPEN': {
      return updateObject(state, {
        isCreatePlanModalOpen: !state.isCreatePlanModalOpen,
      });
    }

    case 'TOGGLE_PLANNED_ORDERS_MODAL_OPEN': {
      return updateObject(state, {
        isPlannedOrdersModalOpen: !state.isPlannedOrdersModalOpen,
      });
    }

    case 'TOGGLE_BULK_UPLOAD_DRIVERS_MODAL_OPEN': {
      return updateObject(state, {
        isBulkUploadDriversModalOpen: !state.isBulkUploadDriversModalOpen,
      });
    }

    //DAWA
    case 'SET_OCCASIONS_LIST': {
      return updateObject(state, {
        occasionsList: action.payload,
      });
    }

    case 'SET_SELECTED_OCCASION': {
      return updateObject(state, {
        selectedOccasion: action.payload,
      });
    }

    case 'SET_IS_LOADER_OPEN_TRUE': {
      return updateObject(state, { isLoading: true });
    }

    case 'SET_IS_LOADER_OPEN_FALSE': {
      return updateObject(state, { isLoading: false });
    }

    case 'SET_NEW_RECEIVED_NOTIFICATION': {
      return updateObject(state, {
        newNotification: action.payload,
      });
    }

    case 'SET_IS_NEW_NOTIFICATION_TRUE': {
      return updateObject(state, {
        isNewNotification: true,
      });
    }

    case 'SET_IS_NEW_NOTIFICATION_FALSE': {
      return updateObject(state, {
        isNewNotification: false,
      });
    }

    case 'SET_NEW_SCANNED_INVITEE': {
      return updateObject(state, {
        newScannedInvitee: action.payload,
      });
    }

    default:
      return state;
  }
};

export default reducer;
