import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { withStyles } from '@material-ui/core/styles';
import CustomSelect from '../shared/CustomSelect';
import {
  Button,
  Dialog,
  Typography,
  DialogContent,
  DialogActions,
  IconButton,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import AppService from '../../services/AppService';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(3),
  },
  closeButton: {
    position: 'absolute',
    // right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          style={{ left: 8 }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const CoverNotesModal = ({
  selectedCover,
  isPopupOpen,
  setIsPopUpOpen,
  occasionId,
  fetchScannedList,
  setSelectedCover,
}) => {
  const [notes, setNotes] = useState(selectedCover?.note ?? '');
  const [disableBtn, setDisableBtn] = useState(false);

  const handleSubmit = async () => {
    let dataBody = {
      note: notes,
    };
    setDisableBtn(true);
    try {
      const res = await AppService.getCoverNotes(dataBody, selectedCover?.id);
      toast.success(res?.data?.message ?? 'تم اضافة الملاحظات');
      setTimeout(() => {
        fetchScannedList(occasionId);
      }, 200);
      setIsPopUpOpen(false);
      setDisableBtn(false);
    } catch (e) {
      toast.error(e?.response?.data?.message ?? 'فشل في اضافة الملاحظات');
      setDisableBtn(false);
    }
  };

  useEffect(() => {
    return () => {
      setSelectedCover('');
    };
  }, []);

  return (
    <div className="">
      <Dialog
        onClose={() => setIsPopUpOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={isPopupOpen}
        style={{ maxHeight: 750, direction: 'rtl' }}
        className="add-driver-modal"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => setIsPopUpOpen(false)}
          style={{ padding: 15 }}
        >
          ملاحظات
        </DialogTitle>
        <DialogContent dividers>
          <form className="form" style={{ padding: '15px 0px', direction: 'ltr' }}>
            <div className="input-wrapper select-input">
              <CustomSelect
                listData={[
                  { id: -1, name: 'لا يوجد ' },
                  { id: 0, name: 'كڤر مفقود' },
                  { id: 1, name: 'كڤر متهالك' },
                  { id: 2, name: 'مستثناه من الكڤر' },
                ]}
                handleChange={(value) => setNotes(value?.id)}
                placeholder="اختر"
                selectedValue={notes}
                optionValue="id"
                optionName="name"
                disableClearIcon
              />
            </div>
          </form>
        </DialogContent>
        <DialogActions style={{ padding: '8px 24px' }}>
          <Button autoFocus onClick={() => setIsPopUpOpen(false)}>
            الغاء
          </Button>
          <Button
            style={{ backgroundColor: '#22af47', color: 'white' }}
            variant="contained"
            onClick={handleSubmit}
            disabled={disableBtn}
          >
            حفظ
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CoverNotesModal;
