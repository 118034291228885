export const getIsLoggedIn = ({ state }) => state.app.isLoggedIn;
export const getDecodedToken = ({ state }) => state.app.decodedToken;
export const getIsDarkMode = ({ state }) => state.app.isDarkMode;
export const getIsLeftSideBarOpen = ({ state }) => state.app.isLeftSideBarOpen;
export const getIsRightSideBarOpen = ({ state }) => state.app.isRightSideBarOpen;
export const getIsBulkUploadDriversModalOpen = ({ state }) =>
  state.app.isBulkUploadDriversModalOpen;

export const getOpsCenterInfo = ({ state }) => state.app.opsCenterInfo;
export const getOpsCenterId = ({ state }) => state.app.opsId;

export const getIsOpsModalOpen = ({ state }) => state.app.isOpsModalOpen;

export const getAllOpsCenters = ({ state }) => state.app.opsCenters;

export const getDefaultZoom = ({ state }) => state.app.defaultZoom;

export const getIsMobileSize = ({ state }) => state.app.isMobileSize;
export const getIsDesktopSize = ({ state }) => state.app.isDesktopSize;

export const getIsDriversListModalOpen = ({ state }) => state.app.isDriversListModalOpen;
export const getIsCreatePlanModalOpen = ({ state }) => state.app.isCreatePlanModalOpen;
export const getIsPlannedOrdersModalOpen = ({ state }) => state.app.isPlannedOrdersModalOpen;

//DAWA
export const getOccasionsList = ({ state }) => state.app.occasionsList;
export const getSelectedOccasion = ({ state }) => state.app.selectedOccasion;
export const getIsloading = ({ state }) => state.app.isLoading;
export const getNewNotification = ({ state }) => state.app.newNotification;
export const getIsNewNotification = ({ state }) => state.app.isNewNotification;
export const getNewScannedInvitee = ({ state }) => state.app.newScannedInvitee;
