import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
//react google maps
import { withGoogleMap, GoogleMap, withScriptjs, Marker, InfoWindow } from 'react-google-maps';
import Geocode from 'react-geocode';
import WindowInfoComponent from './windowInfoComponent';
//map styles
import mapDarkStyles from '../../constants/mapDarkStyles.json';
import mapLightStyles from '../../constants/mapLightStyles.json';
import CancelIcon from '@material-ui/icons/Cancel';
// //actions
import { setSelectedOccasion } from '../../store/app/AppActions';
// //selectors
import { getIsDarkMode, getSelectedOccasion } from '../../store/app/AppSelectors';

let GoogleMapsAPI = 'AIzaSyDW3CyzS8Bfl5FfCC3j9RaF5shuGhAQz9o';

const AsyncMap = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      google={props.google}
      icon={{ url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png' }}
      ref={props.onMapMounted}
      defaultZoom={props.defaultZoom}
      defaultCenter={{ lat: props.latValue, lng: props.lngValue }}
      zoom={props.selectedDriver ? 17 : props.defaultZoom}
      center={{
        lat: props.latValue,
        lng: props.lngValue,
      }}
      direction={props.direction}
      setDirections={props.setDirections}
      disableDefaultUI={false}
      options={{
        styles: props.isDarkMode ? mapDarkStyles : mapLightStyles,
      }}
    >
      <Fragment>
        <Marker
          position={{ lat: props.latValue, lng: props.lngValue }}
          icon={{
            url: `https://cdn.floward.com/web/Files/attachment/group2024@3x-637586606142691557.png`,
            scaledSize: { width: 35, height: 50 },
          }}
        />
        {props.occasions.map((item, i) => (
          <Marker
            key={i}
            position={{ lat: +item.latitude, lng: +item.longitude }}
            icon={{
              url: item.isStarted
                ? 'https://floward.imgix.net/web/files/tickets/53c2e891-5973-4593-b52e-99eb4347bc15.png'
                : 'https://floward.imgix.net/web/files/tickets/aeb49a99-3276-460b-a942-01716105d4cd.png',
              scaledSize: { width: 25, height: 40 },
            }}
            onClick={() => props.dispatch(setSelectedOccasion(item))}
            // onDblClick={() => props.navigate(`/edit-occasion/${item?.id}`)}
          />
        ))}
      </Fragment>
      {props.selectedOccasion && (
        <InfoWindow
          position={{
            lat: +props.selectedOccasion.latitude,
            lng: +props.selectedOccasion.longitude,
          }}
        >
          <Fragment>
            <CancelIcon
              className="window-info-close-icon"
              onClick={() => props.dispatch(setSelectedOccasion(''))}
            />
            <WindowInfoComponent
              navigate={props.navigate}
              selectedOccasion={props.selectedOccasion}
            />
          </Fragment>
        </InfoWindow>
      )}
    </GoogleMap>
  ))
);

const MapComponent = ({ google, occasionsList }) => {
  const refs = {},
    isDarkMode = useSelector((state) => getIsDarkMode({ state })),
    selectedOccasion = useSelector((state) => getSelectedOccasion({ state })),
    [directions, setDirections] = useState(null),
    dispatch = useDispatch(),
    navigate = useNavigate();

  Geocode.setApiKey(GoogleMapsAPI);

  const onMapMounted = (ref) => {
    refs.map = ref;
  };

  return (
    <Fragment>
      <div style={{ height: '100%' }}>
        <Fragment>
          <AsyncMap
            latValue={+29.3821223}
            lngValue={+47.9747789}
            isDarkMode={isDarkMode}
            defaultZoom={11}
            occasions={occasionsList}
            selectedOccasion={selectedOccasion}
            directions={directions}
            setDirections={setDirections}
            dispatch={dispatch}
            navigate={navigate}
            google={google}
            onMapMounted={onMapMounted}
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GoogleMapsAPI}&libraries=places`}
            loadingElement={<div style={{ height: '100%' }} />}
            containerElement={<div style={{ height: '100%' }} />}
            mapElement={<div className="map" style={{ height: '100%' }} />}
          />
        </Fragment>
      </div>
    </Fragment>
  );
};

export default MapComponent;
