import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AppErrorBoundary from '../shared/AppErrorBoundary';
import { convertQueryParamsIntoObject } from '../../constants/Helpers';
import { toast } from 'react-toastify';
//components
import CoversContent from './CoversContent';
import CoversHeader from './CoversHeader';
//actions
import { setOccasionsList, setCoverWebSocketConnection } from '../../store/app/AppActions';
//selectors
import { getOccasionsList, getNewScannedInvitee } from '../../store/app/AppSelectors';
//api
import AppService from '../../services/AppService';

const CoversContainer = () => {
  const occasionsList = useSelector((state) => getOccasionsList({ state }));
  const newScannedInvitee = useSelector((state) => getNewScannedInvitee({ state }));
  const [occasion, setOccasion] = useState('');
  const [scannedList, setScannedList] = useState([]);
  const [isLoading, setIsLoading] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setOccasionsList());
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (location?.search) {
        const queryObj = convertQueryParamsIntoObject(location?.search);
        setOccasion(parseInt(queryObj?.occasionId), 10);
        await fetchScannedList(queryObj?.occasionId);
      }
    };
    fetchData();
  }, []);

  const fetchScannedList = async (occasionId) => {
    try {
      const res = await AppService.getScannedInvitees(occasionId);
      setScannedList(res?.data);
    } catch (err) {
      toast.error(err?.response?.data?.message ?? 'لا توجد دعوات ');
    }
  };

  useEffect(() => {
    if (location?.search) {
      const queryObj = convertQueryParamsIntoObject(location?.search);
      dispatch(setCoverWebSocketConnection(queryObj?.occasionId));
    }
  }, []);

  useEffect(() => {
    if (newScannedInvitee) {
      fetchScannedList(occasion);
    }
  }, [newScannedInvitee]);

  return (
    <AppErrorBoundary>
      <CoversHeader
        setScannedList={setScannedList}
        setIsLoading={setIsLoading}
        occasionsList={occasionsList}
        fetchScannedList={fetchScannedList}
        setOccasion={setOccasion}
        occasion={occasion}
      />
      <CoversContent
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        scannedList={scannedList || []}
        fetchScannedList={fetchScannedList}
        occasionId={occasion}
      />
    </AppErrorBoundary>
  );
};

export default CoversContainer;
