import axios from 'axios';
import { toast } from 'react-toastify';
import { setBaseUrl } from './SetBaseUrl';
import { setIsLoggedInFalse } from '../../store/app/AppActions';
import { store } from '../../index';

const base = setBaseUrl();

export const apiLogisticsService = axios.create({
  baseURL: base.logistics,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('LOGISTICS_TOKEN')}`,
  },
});

export const apiOrderService = axios.create({
  baseURL: base.order,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('LOGISTICS_TOKEN')}`,
  },
});

export const apiGatewayService = axios.create({
  baseURL: base.gateway,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('LOGISTICS_TOKEN')}`,
  },
});

export const apiHomeService = axios.create({
  baseURL: base.home,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('LOGISTICS_TOKEN')}`,
  },
});

export const apiUserService = axios.create({
  baseURL: base.user,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('LOGISTICS_TOKEN')}`,
  },
});

export const apiOccasionService = axios.create({
  baseURL: base.occasion,
});

const requestInterceptor = (config) => {
  // config.headers['Authorization'] = `Bearer ${localStorage.getItem('LOGISTICS_TOKEN')}`;
  return config;
};

const requestInterceptorError = (error) => {
  return Promise.reject(error);
};

const responseInterceptor = (response) => {
  return response;
};

const responseInterceptorError = (error) => {
  if (error.response?.status === 401) {
    toast.error('401 Unauthorized');
    store.dispatch(setIsLoggedInFalse());
  }
  return Promise.reject(error);
};

//request
apiLogisticsService.interceptors.request.use(requestInterceptor, requestInterceptorError);

apiOrderService.interceptors.request.use(requestInterceptor, requestInterceptorError);

apiGatewayService.interceptors.request.use(requestInterceptor, requestInterceptorError);

apiHomeService.interceptors.request.use(requestInterceptor, requestInterceptorError);

apiUserService.interceptors.request.use(requestInterceptor, requestInterceptorError);

//response
apiLogisticsService.interceptors.response.use(responseInterceptor, responseInterceptorError);

apiOrderService.interceptors.response.use(responseInterceptor, responseInterceptorError);

apiGatewayService.interceptors.response.use(responseInterceptor, responseInterceptorError);

apiHomeService.interceptors.response.use(responseInterceptor, responseInterceptorError);

apiUserService.interceptors.response.use(responseInterceptor, responseInterceptorError);
