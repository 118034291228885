import { useSelector } from 'react-redux';
//selectors
import { getIsloading } from '../../store/app/AppSelectors';

const AppLoader = () => {
  const isLoaderOpen = useSelector((state) => getIsloading({ state }));
  return (
    <div className="pageLoader" style={{ display: isLoaderOpen ? '' : 'none' }}>
      <div className="loader"></div>
    </div>
  );
};

export default AppLoader;
