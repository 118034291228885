import React, { useState, useEffect } from 'react';
import AppErrorBoundary from '../shared/AppErrorBoundary';
import { Grid, TextField, Button } from '@material-ui/core';
import CustomSelect from '../shared/CustomSelect';
import { toast } from 'react-toastify';
//API
import AppService from '../../services/AppService';

const OccasionPoll = ({ pollingData, ownerToken, fetchPollingData, hasCovers }) => {
  const [allowedNames, setAllowedNames] = useState('');
  const [isKidsAllowed, setIsKidsAllowed] = useState('');
  const [isServantAllowed, setIsServantAllowed] = useState('');
  const [duplicationEntry, setDuplicationEntry] = useState('');
  const [contactDetails, setContactDetails] = useState('');
  const [additionalDetails, setAdditionalDetails] = useState('');
  const [isDisableBtn, setIsDisableBtn] = useState(false);

  useEffect(() => {
    if (pollingData && Object.keys(pollingData).length > 0) {
      setAllowedNames(pollingData?.allowedNames ?? '');
      setIsKidsAllowed(pollingData?.kidsAllowed ?? '');
      setIsServantAllowed(pollingData?.servantsAllowed ?? '');
      setDuplicationEntry(pollingData?.duplicateInvitationEntry ?? '');
      setContactDetails(pollingData?.contactDetails ?? '');
      setAdditionalDetails(pollingData?.additionalDetails ?? '');
    }
  }, [pollingData]);

  const handleUpdatePolling = async () => {
    const dataBody = {
      allowedNames,
      kidsAllowed: isKidsAllowed || 2,
      servantsAllowed: isServantAllowed || 2,
      duplicateInvitationEntry: duplicationEntry || 2,
      contactDetails,
      additionalDetails,
    };
    setIsDisableBtn(true);
    try {
      await AppService.getUpdatePolling(dataBody, ownerToken);
      setIsDisableBtn(false);
      toast.success('تم حفظ البيانات بنجاح');
      setTimeout(() => {
        fetchPollingData(ownerToken);
      }, 200);
    } catch (err) {
      setIsDisableBtn(false);
      toast.error(err?.response?.data?.message ?? 'فشل في حفظ بيانات الاستبيان');
    }
  };

  return (
    <AppErrorBoundary>
      <div className="add-driver-modal">
        <form className="form">
          <Grid>
            <Grid item sm={12}>
              <div className="input-wrapper">
                <label className="input-label" htmlFor="driver-sdate">
                  الاسماء المسموح لهم بالحضور بدون دعوات
                </label>
                <TextField
                  id="driver-sdate"
                  variant="outlined"
                  type="text"
                  multiline
                  value={allowedNames}
                  onChange={(e) => setAllowedNames(e.target.value)}
                />
              </div>
            </Grid>
            <Grid item sm={12}>
              <div className="input-wrapper select-input">
                <label htmlFor="driver-type" className="input-label">
                  هل مسموح بدخول الاطفال؟
                </label>
                <AppErrorBoundary>
                  <CustomSelect
                    listData={[
                      { id: 0, name: 'نعم' },
                      { id: 1, name: 'لا' },
                      { id: 2, name: 'اسماء محدده' },
                    ]}
                    handleChange={(value) => setIsKidsAllowed(value?.id)}
                    placeholder="اختر"
                    selectedValue={isKidsAllowed}
                    optionValue="id"
                    optionName="name"
                    disableClearIcon
                  />
                </AppErrorBoundary>
              </div>
            </Grid>
            <Grid item sm={12}>
              <div className="input-wrapper select-input">
                <label htmlFor="driver-type" className="input-label">
                  هل مسموح بدخول الخدم؟
                </label>
                <AppErrorBoundary>
                  <CustomSelect
                    listData={[
                      { id: 0, name: 'نعم' },
                      { id: 1, name: 'لا' },
                      { id: 2, name: 'اسماء محدده' },
                    ]}
                    handleChange={(value) => setIsServantAllowed(value?.id)}
                    placeholder="اختر"
                    selectedValue={isServantAllowed}
                    optionValue="id"
                    optionName="name"
                    disableClearIcon
                  />
                </AppErrorBoundary>
              </div>
            </Grid>
            <Grid item sm={12}>
              <div className="input-wrapper select-input">
                <label htmlFor="driver-type" className="input-label">
                  طريقة التعامل مع الحضور بدون دعوة
                </label>
                <AppErrorBoundary>
                  <CustomSelect
                    listData={[
                      { id: 0, name: 'لا يسمح لهم بالدخول نهائيا' },
                      { id: 1, name: 'التواصل مع اصحاب الحفلة' },
                      { id: 2, name: 'طبقا للتعليمات' },
                    ]}
                    handleChange={(value) => setDuplicationEntry(value?.id)}
                    placeholder="اختر"
                    selectedValue={duplicationEntry}
                    optionValue="id"
                    optionName="name"
                    disableClearIcon
                  />
                </AppErrorBoundary>
              </div>
            </Grid>
            <Grid item sm={12}>
              <div className="input-wrapper">
                <label className="input-label" htmlFor="driver-sdate">
                  اسم و رقم التواصل
                </label>
                <TextField
                  id="driver-sdate"
                  variant="outlined"
                  type="text"
                  multiline
                  value={contactDetails}
                  onChange={(e) => setContactDetails(e.target.value)}
                />
              </div>
            </Grid>
            <Grid item sm={12}>
              <div className="input-wrapper">
                <label className="input-label" htmlFor="driver-sdate">
                  {hasCovers ? 'الاسماء المستثناه من الكڤرات' : 'تعليمات اضافيه ان وجدت'}
                </label>
                <TextField
                  id="driver-sdate"
                  variant="outlined"
                  type="text"
                  multiline
                  value={additionalDetails}
                  onChange={(e) => setAdditionalDetails(e.target.value)}
                />
              </div>
            </Grid>
            <Button
              style={{ backgroundColor: '#006782', color: 'white', margin: 5 }}
              variant="contained"
              onClick={handleUpdatePolling}
              disabled={isDisableBtn}
            >
              حفظ
            </Button>
          </Grid>
        </form>
      </div>
    </AppErrorBoundary>
  );
};

export default OccasionPoll;
