import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Card,
  CardContent,
  Grid,
  TextField,
  CardActions,
  Button,
  Divider,
  CardHeader,
  Dialog,
  DialogActions,
} from '@material-ui/core';
import InviteesComponent from './InviteesComponent';
import AppErrorBoundary from '../shared/AppErrorBoundary';
import CustomSelect from '../shared/CustomSelect';
import AutoComplete from 'react-google-autocomplete';
import { flagIcon } from '../../constants/Helpers';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import WarningIcon from '@material-ui/icons/Warning';
import ProcessingLoader from '../shared/ProcessingLoader';
import DeleteOccasionsModal from '../modals/DeleteOccasionsModal';
//selectors
import { getOccasionsList } from '../../store/app/AppSelectors';
//api
import AppService from '../../services/AppService';

const AddOccasionForm = ({
  occasionId,
  occasionDetails,
  setOccasionDetails,
  clonedOccasionDetails,
  fetchOccasionById,
}) => {
  const [occasionName, setOccasionName] = useState('');
  const [count, setCount] = useState(null);
  const [occasionDate, setOccasionDate] = useState('');
  const [addressName, setAddressName] = useState('');
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [active, setActive] = useState(null);
  const [designImg, setDesignImg] = useState('');
  const [brochureUrl, setBrochureUrl] = useState('');
  const [attendFile, setAttendFile] = useState('');
  const [isLoading, setIsLoading] = useState('');
  const [selectedInvitees, setSelectedInvitees] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [whatsappMsg, setWhatsappMsg] = useState('');
  const [avenue, setAvenue] = useState('');
  const [processMsg, setProcessMsg] = useState('');
  const [ownerToken, setOwnerToken] = useState('');
  const [standDesignUrl, setStandDesignUrl] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [messageType, setMessageType] = useState(null);
  const [isReminder, setIsReminder] = useState(false);
  const [parentId, setParentId] = useState(0);
  const [hasChildren, setHasChildren] = useState(0);
  const [selectedCount, setSelectedCount] = useState(0);
  const [hasCovers, setHasCovers] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const today = new Date().toISOString().split('T')[0];
  const occasionsList = useSelector((state) => getOccasionsList({ state }));

  useEffect(() => {
    if (occasionDetails && Object.keys(occasionDetails).length > 0) {
      setOccasionName(occasionDetails?.name ?? '');
      setAddressName(occasionDetails?.addressName ?? '');
      setLatitude(occasionDetails?.latitude ?? '');
      setLongitude(occasionDetails?.longitude ?? '');
      setOccasionDate(occasionDetails?.date ?? '');
      setCount(occasionDetails?.totalNumberOfAttendees);
      setBrochureUrl(occasionDetails?.brochureUrl);
      setIsProcessing(occasionDetails?.isProcessing);
      setProcessMsg(occasionDetails?.processingMessage);
      setWhatsappMsg(occasionDetails?.whatsappMessage);
      setAvenue(occasionDetails?.avenueMessage);
      setOwnerToken(occasionDetails?.ownerToken);
      setActive(occasionDetails?.isActive);
      setParentId(occasionDetails?.parentId);
      setHasChildren(occasionDetails?.hasChildren);
      setStandDesignUrl(occasionDetails?.standDesignUrl);
      setHasCovers(occasionDetails?.hasCovers);
    }
  }, [occasionDetails]);

  const fetchOccasions = async () => {
    try {
      const res = await AppService.getOccasionsList();
      dispatch({
        type: 'SET_OCCASIONS_LIST',
        payload: res.data,
      });
    } catch (err) {
      // toast.error(err?.response?.data?.message)
    }
  };

  useEffect(() => {
    fetchOccasions();
  }, []);

  const handlePlaceSelected = (place) => {
    if (place?.geometry && place?.geometry?.location) {
      const { lat, lng } = place?.geometry?.location;
      const placeName = inputRef?.current?.value;
      setAddressName(placeName);
      setLatitude(lat);
      setLongitude(lng);
    } else {
      toast.error('مكان غير معروف');
    }
  };

  const handleUpdateOccasion = async () => {
    setIsLoading(true);
    const data = {
      name: occasionName,
      addressName,
      latitude,
      longitude,
      date: occasionDate,
      isActive: active === 1 ? true : false,
      attendees: attendFile,
      Brochure: designImg,
      count,
      whatsappMessage: whatsappMsg,
      avenueMessage: avenue,
      parentId,
      standDesign: standDesignUrl,
      hasCovers,
    };
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    try {
      if (occasionId) {
        await AppService.editOccasion(formData, occasionId);
        setIsLoading(false);
        toast.success('تم التعديل علي المناسبه بنجاح');
        setTimeout(() => {
          fetchOccasionById(occasionId);
        }, 500);
      } else {
        const res = await AppService.addNewOccasion(formData);
        setIsLoading(false);
        toast.success('تم اضافة مناسبة جديدة بنجاح');
        setTimeout(() => {
          window.location.href = `edit-occasion/${res?.data?.id}`;
        }, 500);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message ?? 'فشل في تعديل المناسبة ');
      setIsLoading(false);
    }
  };

  const handleSendWhatsapp = async () => {
    setIsLoading(true);
    const data = {
      inviteeIds: selectedInvitees?.map((el) => el.id),
      count: selectedCount,
      occasionId,
      isReminder,
    };
    try {
      const res =
        messageType === 0
          ? await AppService.getSendWhatsapp(data)
          : await AppService.getSendBorchor(data);
      setIsLoading(false);
      toast.success(res?.data?.message ?? 'تم الارسال بنجاح');
      setIsPopupOpen(false);
      setTimeout(() => {
        fetchOccasionById(occasionId);
      }, 500);
    } catch (err) {
      toast.error(err?.response?.data?.message ?? 'فشل في الارسال ');
      setIsLoading(false);
      setIsPopupOpen(false);
    }
  };

  return (
    <>
      <Dialog
        open={isPopupOpen}
        onClose={() => setIsPopupOpen((prev) => !prev)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {isLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <ProcessingLoader /> <span>جاري الارسال ...</span>
          </div>
        ) : (
          <>
            <div
              style={{
                padding: 30,
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img src={flagIcon.trueIcon} style={{ width: 30, marginRight: 5 }} />
              تأكيد الارسال
            </div>
            <div style={{ textAlign: 'center', paddingBottom: 5, fontSize: 20 }}>
              العدد : {selectedInvitees?.length > 0 ? selectedInvitees?.length : selectedCount}
            </div>
            <Divider />
          </>
        )}
        <DialogActions
          style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}
        >
          <Button
            variant="contained"
            type="submit"
            onClick={() => setIsPopupOpen(false)}
            color="default"
          >
            الغاء
          </Button>
          <Button
            style={{ backgroundColor: '#006782', color: 'white', margin: 5 }}
            variant="contained"
            type="submit"
            onClick={handleSendWhatsapp}
            disabled={isLoading}
          >
            تأكيد
          </Button>
        </DialogActions>
      </Dialog>
      <Card className="card-wrapper">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <CardHeader title={occasionId ? 'تعديل المناسبة' : 'اضافة مناسبة جديدة'} />
          {occasionId && (
            <Button
              style={{ backgroundColor: '#F50157', color: 'white', marginLeft: 15 }}
              variant="contained"
              onClick={() => setIsDeletePopupOpen(true)}
            >
              حذف المناسبة
            </Button>
          )}
        </div>
        <Divider />
        <CardContent>
          <div className="add-driver-modal">
            <form className="form">
              <Grid container>
                <Grid container md={9} sm={12}>
                  <Grid item md={4} sm={12}>
                    <div className="input-wrapper">
                      <label htmlFor="driver-name" className="input-label">
                        وصف المناسبة <span className="red-color"> *</span>
                      </label>
                      <input
                        id="driver-name"
                        onChange={(e) => setOccasionName(e.target.value)}
                        className="input"
                        type="text"
                        value={occasionName}
                      />
                    </div>
                  </Grid>
                  <Grid item md={4} sm={12}>
                    <div className="input-wrapper">
                      <label htmlFor="driver-phone" className="input-label">
                        عدد الدعوات<span className="red-color"> *</span>
                      </label>
                      <input
                        id="driver-phone"
                        onChange={(e) => setCount(e.target.value)}
                        className="input"
                        type="number"
                        value={count}
                      />
                    </div>
                  </Grid>
                  <Grid item md={4} sm={12}>
                    <div className="input-wrapper">
                      <label className="input-label" htmlFor="driver-sdate">
                        تاريخ المناسبة
                      </label>
                      <TextField
                        id="driver-sdate"
                        variant="outlined"
                        type="date"
                        className="date-picker"
                        value={occasionDate?.split('T')[0]}
                        onChange={(e) => setOccasionDate(e.target.value)}
                        inputProps={{
                          min: today,
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item md={4} sm={12}>
                    <div className="input-wrapper select-input">
                      <label htmlFor="driver-type" className="input-label">
                        المناسبه مفعله
                      </label>
                      <AppErrorBoundary>
                        <CustomSelect
                          listData={[
                            { id: 1, name: 'نعم' },
                            { id: 2, name: 'لا' },
                          ]}
                          handleChange={(value) => setActive(value?.id)}
                          placeholder="Select a type"
                          selectedValue={active ? 1 : 2}
                          optionValue="id"
                          optionName="name"
                          disableClearIcon
                        />
                      </AppErrorBoundary>
                    </div>
                  </Grid>
                  <Grid item md={4} sm={12}>
                    <div className="input-wrapper">
                      <label htmlFor="driver-photo" className="input-label">
                        مكان المناسبة
                      </label>
                      <AutoComplete
                        className="map-auto-complete"
                        ref={inputRef}
                        apiKey={'AIzaSyDW3CyzS8Bfl5FfCC3j9RaF5shuGhAQz9o'}
                        onPlaceSelected={handlePlaceSelected}
                        options={{
                          types: ['establishment'],
                          componentRestrictions: { country: 'KW' },
                        }}
                        onChange={(e) => setAddressName(e.target.value)}
                        value={addressName}
                      />
                    </div>
                  </Grid>
                  <Grid item md={4} sm={12}>
                    <div className="input-wrapper">
                      <label htmlFor="driver-photo" className="input-label">
                        بروشور الدعوة
                      </label>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          id="driver-photo"
                          onChange={(e) => setDesignImg(e.target.files[0])}
                          className="input"
                          type="file"
                          accept="image/*"
                          // disabled={hasChildren}
                        />
                        {brochureUrl ? (
                          <img src={flagIcon.trueIcon} style={{ width: 33, marginRight: 5 }} />
                        ) : (
                          <img src={flagIcon.falseIcon} style={{ width: 33, marginRight: 5 }} />
                        )}
                      </div>
                    </div>
                  </Grid>
                  <Grid item md={4} sm={12}>
                    <div className="input-wrapper">
                      <label className="input-label" htmlFor="driver-sdate">
                        رسالة الواتس اب
                      </label>
                      <TextField
                        id="driver-sdate"
                        variant="outlined"
                        type="text"
                        multiline
                        value={whatsappMsg}
                        onChange={(e) => setWhatsappMsg(e.target.value)}
                      />
                    </div>
                  </Grid>
                  <Grid item md={4} sm={12}>
                    <div className="input-wrapper">
                      <label className="input-label" htmlFor="driver-sdate">
                        الوقت و المكان
                      </label>
                      <TextField
                        id="driver-sdate"
                        variant="outlined"
                        type="text"
                        multiline
                        value={avenue}
                        onChange={(e) => setAvenue(e.target.value)}
                      />
                    </div>
                  </Grid>
                  <Grid item md={4} sm={12}>
                    <div className="input-wrapper" style={{ marginBottom: 5 }}>
                      <label htmlFor="driver-photo" className="input-label">
                        ملف الدعوات
                      </label>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          id="driver-photo"
                          onChange={(e) => setAttendFile(e?.target?.files[0])}
                          className="input"
                          type="file"
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          disabled={hasChildren}
                        />
                        {clonedOccasionDetails?.invitees?.length > 0 ? (
                          <img src={flagIcon.trueIcon} style={{ width: 33, marginRight: 5 }} />
                        ) : (
                          <img src={flagIcon.falseIcon} style={{ width: 33, marginRight: 5 }} />
                        )}
                      </div>
                    </div>
                    <label style={{ paddingRight: 20 }}>
                      (
                      <a
                        href="https://dawastorage.blob.core.windows.net/dawa-brochures/template.xlsx"
                        title="Download bulk order template"
                      >
                        تحميل الملف
                      </a>
                      )
                    </label>
                  </Grid>
                  {parentId === 0 && (
                    <Grid item md={6} sm={12}>
                      <div className="input-wrapper">
                        <label htmlFor="driver-photo" className="input-label">
                          تصميم الستاند
                        </label>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <input
                            id="driver-photo"
                            onChange={(e) => setStandDesignUrl(e.target.files[0])}
                            className="input"
                            type="file"
                            accept="image/*"
                          />
                          {standDesignUrl ? (
                            <img src={flagIcon.trueIcon} style={{ width: 33, marginRight: 5 }} />
                          ) : (
                            <img src={flagIcon.falseIcon} style={{ width: 33, marginRight: 5 }} />
                          )}
                        </div>
                      </div>
                    </Grid>
                  )}

                  {ownerToken && (
                    <Grid item md={6} sm={12}>
                      <div
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `https://dawabarcode.com/occasion-live/${ownerToken}`
                          );
                          toast.success('تم نسخ الرابط');
                        }}
                        className="input-wrapper"
                      >
                        <label className="input-label" htmlFor="driver-sdate">
                          نسخ رابط المتابعة
                        </label>

                        <div
                          style={{
                            cursor: 'pointer',
                            textDecoration: 'underline',
                            color: 'darkblue',
                          }}
                        >
                          {`https://dawabarcode.com/occasion-live/${ownerToken}`}
                        </div>
                      </div>
                    </Grid>
                  )}
                  {ownerToken && hasCovers && (
                    <Grid item md={6} sm={12}>
                      <div
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `https://dawabarcode.com/covers-live/${ownerToken}`
                          );
                          toast.success('تم نسخ الرابط');
                        }}
                        className="input-wrapper"
                      >
                        <label className="input-label" htmlFor="driver-sdate">
                          نسخ رابط الكڤرات
                        </label>

                        <div
                          style={{
                            cursor: 'pointer',
                            textDecoration: 'underline',
                            color: 'darkblue',
                          }}
                        >
                          {`https://dawabarcode.com/covers-live/${ownerToken}`}
                        </div>
                      </div>
                    </Grid>
                  )}
                </Grid>
                {brochureUrl && (
                  <Grid md={3} sm={12}>
                    <div style={{ textAlign: 'end', paddingTop: 15 }}>
                      <img
                        onClick={() => window.open(brochureUrl)}
                        style={{ width: '65%', cursor: 'pointer' }}
                        src={brochureUrl}
                      />
                    </div>
                  </Grid>
                )}
              </Grid>
              <Grid container>
                <Grid item md={4} sm={12}>
                  <div
                    className="select-input"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginRight: 15,
                      marginTop: 10,
                    }}
                  >
                    <label htmlFor="driver-type" className="input-label">
                      رساله تذكيرية
                    </label>
                    <input
                      style={{
                        marginRight: 7,
                        cursor: 'pointer',
                        transform: 'scale(1.2)',
                      }}
                      id="driver-phone"
                      onChange={() => {
                        setSelectedCount(0);
                        setIsReminder((prev) => !prev);
                      }}
                      className="input"
                      type="checkbox"
                      checked={isReminder}
                    />
                  </div>

                  <div
                    className="select-input"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginRight: 15,
                      marginTop: 10,
                    }}
                  >
                    <label htmlFor="driver-type" className="input-label">
                      مناسبة كڤرات
                    </label>
                    <input
                      style={{
                        marginRight: 7,
                        cursor: 'pointer',
                        transform: 'scale(1.2)',
                      }}
                      id="driver-phone"
                      onChange={() => setHasCovers((prev) => !prev)}
                      className="input"
                      type="checkbox"
                      checked={hasCovers}
                    />
                  </div>
                </Grid>
                <Grid item md={8} sm={12}>
                  <div className="select-input" style={{ display: 'flex', alignItems: 'center' }}>
                    <label
                      onClick={() => {
                        parentId && brochureUrl && window.open(`/edit-occasion/${parentId}`);
                      }}
                      htmlFor="driver-type"
                      className="input-label"
                      style={{ textDecoration: 'underline', cursor: 'pointer', color: 'blue' }}
                    >
                      تابع لمناسبة اخري
                    </label>
                    <div style={{ width: '40%', marginRight: 7 }}>
                      <CustomSelect
                        listData={occasionsList}
                        handleChange={(value) => setParentId(value?.id)}
                        placeholder="اختر المناسبة"
                        selectedValue={parentId}
                        optionValue="id"
                        optionName="name"
                        disableClearIcon
                        isDisabled={brochureUrl}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </form>
          </div>
        </CardContent>
        <Divider />
        <CardActions>
          {isProcessing ? (
            <>
              <ProcessingLoader /> <span>جاري ارفاق الدعوات...</span>
            </>
          ) : (
            <>
              <Button
                style={{ backgroundColor: '#006782', color: 'white', margin: 5 }}
                variant="contained"
                type="submit"
                onClick={handleUpdateOccasion}
                disabled={isLoading || !occasionName}
              >
                حفظ
              </Button>
              {occasionId &&
                whatsappMsg &&
                (selectedInvitees?.length > 0 || selectedCount !== 0) && (
                  <Button
                    style={{ margin: 5, backgroundColor: '#22af47', color: 'white' }}
                    variant="contained"
                    disabled={
                      isLoading ||
                      (!selectedInvitees?.length > 0 && selectedCount === 0) ||
                      !whatsappMsg
                    }
                    endIcon={<WhatsAppIcon style={{ marginRight: 7 }} />}
                    onClick={() => {
                      setMessageType(0);
                      setIsPopupOpen(true);
                    }}
                  >
                    ارسال الرسالة {isReminder ? 'التذكيرية' : 'الترحيبيه'}
                  </Button>
                )}
              {occasionId &&
                brochureUrl &&
                (selectedInvitees?.length > 0 || selectedCount !== 0) && (
                  <Button
                    style={{ margin: 5, backgroundColor: '#22af47', color: 'white' }}
                    variant="contained"
                    disabled={
                      isLoading ||
                      (!selectedInvitees?.length > 0 && selectedCount === 0) ||
                      !brochureUrl
                    }
                    endIcon={<WhatsAppIcon style={{ marginRight: 7 }} />}
                    onClick={() => {
                      setMessageType(1);
                      setIsPopupOpen(true);
                    }}
                  >
                    ارسال البرشور
                  </Button>
                )}
              {processMsg && (
                <div className="warrning-card">
                  <WarningIcon style={{ marginLeft: 5 }} />
                  {processMsg}
                </div>
              )}
            </>
          )}
        </CardActions>
      </Card>
      {occasionId && (
        <InviteesComponent
          inviteesList={occasionDetails?.invitees}
          setOccasionDetails={setOccasionDetails}
          clonedOccasionDetails={clonedOccasionDetails}
          setSelectedInvitees={setSelectedInvitees}
          fetchOccasionById={fetchOccasionById}
          occasionId={occasionId}
          selectedCount={selectedCount}
          setSelectedCount={setSelectedCount}
          isReminder={isReminder}
        />
      )}
      {isDeletePopupOpen && (
        <DeleteOccasionsModal
          occasionId={occasionId}
          isDeletePopupOpen={isDeletePopupOpen}
          setIsDeletePopupOpen={setIsDeletePopupOpen}
          fetchOccasionById={fetchOccasionById}
        />
      )}
    </>
  );
};

export default AddOccasionForm;
