import React from 'react';
//components
import AddOccasionForm from '../components/addOccasion/AddOccasionForm';
import AppErrorBoundary from '../components/shared/AppErrorBoundary';

const OccasionsPage = () => {
  return (
    <div className="page-wrapper">
      <AppErrorBoundary>
        <AddOccasionForm />
      </AppErrorBoundary>
    </div>
  );
};

export default OccasionsPage;
