import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppErrorBoundary from './components/shared/AppErrorBoundary';
import AppLoader from './components/shared/AppLoader';
import { getIsLoggedIn, getIsRightSideBarOpen } from './store/app/AppSelectors';
import Navbar from './components/shared/Navbar';
import RightSidebar from './components/home/rightSidebar/RightSidebar';
import { routes } from './routing/routesConfig';
//fonts
import '@fontsource-variable/reem-kufi';
import '@fontsource/aref-ruqaa';

function App() {
  const isLoggedIn = useSelector((state) => getIsLoggedIn({ state }));
  const isRightSideBarOpen = useSelector((state) => getIsRightSideBarOpen({ state }));
  const [isMobile, setIsMobile] = useState(false);
  const [hideEle, setHideEle] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window?.innerWidth <= 600);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const appBodyStyle = {
    height: '100%',
    marginInlineStart:
      isLoggedIn &&
      !isMobile &&
      isRightSideBarOpen &&
      !window?.location?.pathname?.includes('greetings')
        ? '360px'
        : 0,
    padding:
      isLoggedIn && !isMobile && !window?.location?.pathname?.includes('greetings') ? '20px' : 0,
    // backgroundColor: '#F8F8F8',
  };

  useEffect(() => {
    if (
      window?.location?.pathname?.includes('occasion-live') ||
      window?.location?.pathname?.includes('greetings') ||
      window?.location?.pathname?.includes('covers-live') ||
      window?.location?.pathname?.includes('apologize')
    ) {
      setHideEle(true);
    }
  });

  return (
    <Router>
      <div className="app" dir="rtl" style={appBodyStyle}>
        <AppLoader />
        {!hideEle && (
          <>
            <AppErrorBoundary>
              <Navbar />
            </AppErrorBoundary>
            <AppErrorBoundary>
              <RightSidebar />
            </AppErrorBoundary>
          </>
        )}
        <Routes>
          {routes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
        </Routes>
        <ToastContainer position="bottom-right" />
      </div>
    </Router>
  );
}

export default App;
