import React, { useState } from 'react';
import { formatCustomDateTime, flagIcon, timeFormatHHMM } from '../../constants/Helpers';
import MaterialDataGrid from '../../components/shared/MaterialDataGrid';
import { toast } from 'react-toastify';
//components
import CheckInModal from '../modals/CheckInModal';
import CheckOutModal from '../modals/CheckOutModal';
import CoverNotesModal from '../modals/CoverNotesModal';
//helpers
import { downloadFile, coverNotedEnum } from '../../constants/Helpers';
//material ui
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
//shared
import SectionLoader from '../shared/SectionLoader';
import AppErrorBoundary from '../shared/AppErrorBoundary';
//api
import AppService from '../../services/AppService';

const CoversContent = ({ scannedList, isLoading, fetchScannedList, occasionId }) => {
  const [disableBtn, setDisableBtn] = useState(false);
  const [selectedCover, setSelectedCover] = useState('');
  const [isCheckInModalOpen, setIsCheckInModalOpen] = useState(false);
  const [isCheckOutModalOpen, setIsCheckOutModalOpen] = useState(false);
  const [isNotesModalOpen, setIsNotsModalOpen] = useState(false);

  const phoneCoverStatusIcons = {
    0: <img src={flagIcon.falseIcon} style={{ width: 30 }} />,
    1: (
      <img
        src="https://imagecdn.prod.floward.com/web/files/tickets/79d6520e-258a-477c-ab34-92ffa0881033.jpg"
        style={{ width: 90 }}
      />
    ),
    2: (
      <>
        <img
          src="https://imagecdn.prod.floward.com/web/files/tickets/55c6b8f5-1d9d-4dfc-96c2-bdc7d498cfcd.jpg"
          style={{ width: 90 }}
        />
      </>
    ),
  };

  const columns = [
    {
      title: 'اسم المدعو',
      render: (rowData) => <span>{rowData?.invitee?.name}</span>,
      customFilterAndSearch: (term, rowData) => rowData?.invitee?.name?.indexOf(term) != -1,
    },
    {
      title: 'رقم التليفون',
      render: (rowData) => <span>{rowData?.invitee?.phone}</span>,
      customFilterAndSearch: (term, rowData) => rowData?.invitee?.phone?.indexOf(term) != -1,
    },
    {
      title: 'رقم الكڤر',
      field: 'coverNumber',
      render: (rowData) => (
        <span>
          {rowData?.coverNumber || <img src={flagIcon.falseIcon} style={{ width: 30 }} />}
        </span>
      ),
    },
    {
      title: 'الحالة',
      //   render: (rowData) => <span>{coversTypeEnum[rowData?.phoneCoverStatus]}</span>,
      render: (rowData) => <span>{phoneCoverStatusIcons[rowData?.phoneCoverStatus] || ''}</span>,
    },
    {
      title: 'وقت الدخول',
      field: 'checkedInDate',
      render: (rowData) => (
        <span>
          {rowData?.checkedInDate ? (
            timeFormatHHMM(rowData?.checkedInDate)
          ) : (
            <img src={flagIcon.falseIcon} style={{ width: 30 }} />
          )}
        </span>
      ),
    },
    {
      title: 'وقت الخروج',
      field: 'checkedOutDate',
      render: (rowData) => (
        <span>
          {rowData?.checkedOutDate ? (
            timeFormatHHMM(rowData?.checkedOutDate)
          ) : (
            <img src={flagIcon.falseIcon} style={{ width: 30 }} />
          )}
        </span>
      ),
    },
    {
      title: 'تسجيل',
      render: (rowData) => (
        <>
          <Button
            style={{
              backgroundColor: rowData?.phoneCoverStatus === 0 ? '#22af47' : '#F50157',
              color: 'white',
              display: rowData?.phoneCoverStatus === 2 ? 'none' : '',
            }}
            variant="contained"
            size="small"
            onClick={() => {
              handleCheckInOut(rowData);
            }}
          >
            {rowData?.phoneCoverStatus === 0
              ? 'دخول'
              : rowData?.phoneCoverStatus === 1
              ? 'خروج'
              : ''}
          </Button>
          <Button
            style={{
              backgroundColor: '#006782',
              color: 'white',
              margin: 3,
            }}
            variant="contained"
            size="small"
            onClick={() => {
              setSelectedCover(rowData);
              setIsNotsModalOpen(true);
            }}
          >
            ملاحظات
          </Button>
          <p style={{ color: 'gray' }}>{coverNotedEnum[rowData?.note]}</p>
        </>
      ),
    },
  ];

  const handleCheckInOut = (rowData) => {
    if (rowData?.phoneCoverStatus === 0) {
      setSelectedCover(rowData);
      setIsCheckInModalOpen(true);
    } else if (rowData?.phoneCoverStatus === 1) {
      setSelectedCover(rowData);
      setIsCheckOutModalOpen(true);
    }
  };

  const options = {
    paging: false,
    showTitle: false,
    columnsButton: false,
    sorting: false,
    exportButton: false,
    toolbar: true,
    rowStyle: (rowData) => ({
      backgroundColor: rowData?.phoneCoverStatus === 0 ? '#ffcccb' : '',
    }),
  };

  //actions
  const actions = [
    {
      icon: () => (
        <Button
          style={{ backgroundColor: '#22af47' }}
          variant="contained"
          color="primary"
          size="small"
        >
          اجمالي الكڤرات {scannedList?.length}
        </Button>
      ),
      isFreeAction: true,
    },
    {
      icon: () => (
        <Button
          onClick={handleExportPdf}
          variant="contained"
          color="success"
          size="small"
          disabled={disableBtn}
        >
          تقرير المناسبة
        </Button>
      ),
      isFreeAction: true,
    },
  ];

  const handleExportPdf = async () => {
    setDisableBtn(true);
    try {
      const res = await AppService.getExportCoversReport(occasionId);
      downloadFile(res?.data, 'covers_report.pdf');
      setDisableBtn(false);
    } catch (err) {
      toast.error(err?.response?.data?.message ?? 'فشل في تحميل التقرير ');
      setDisableBtn(false);
    }
  };

  return (
    <div className="attendence-wrapper cover-page-container">
      <AppErrorBoundary>
        {isLoading ? (
          <SectionLoader />
        ) : (
          <TableContainer component={Paper}>
            {scannedList?.length > 0 ? (
              <MaterialDataGrid
                data={scannedList}
                columns={columns}
                options={options}
                actions={actions}
              />
            ) : (
              <p style={{ textAlign: 'center', color: 'red', fontFamily: 'Roboto' }}>
                لا يوجد حضور
              </p>
            )}
          </TableContainer>
        )}
      </AppErrorBoundary>
      {isCheckInModalOpen && (
        <CheckInModal
          isPopupOpen={isCheckInModalOpen}
          setIsPopUpOpen={setIsCheckInModalOpen}
          fetchScannedList={fetchScannedList}
          selectedCover={selectedCover}
          occasionId={occasionId}
          setSelectedCover={setSelectedCover}
        />
      )}
      {isCheckOutModalOpen && (
        <CheckOutModal
          isPopupOpen={isCheckOutModalOpen}
          setIsPopUpOpen={setIsCheckOutModalOpen}
          fetchScannedList={fetchScannedList}
          selectedCover={selectedCover}
          occasionId={occasionId}
          setSelectedCover={setSelectedCover}
        />
      )}
      {isNotesModalOpen && (
        <CoverNotesModal
          isPopupOpen={isNotesModalOpen}
          setIsPopUpOpen={setIsNotsModalOpen}
          fetchScannedList={fetchScannedList}
          selectedCover={selectedCover}
          occasionId={occasionId}
          setSelectedCover={setSelectedCover}
        />
      )}
    </div>
  );
};

export default CoversContent;
