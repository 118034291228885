import React, { useState } from 'react';
import AppErrorBoundary from '../shared/AppErrorBoundary';
//components
import OccasionsContent from './OccasionsContent';
import OccasionsHeader from './OccasionsHeader';

const OccasionsContainer = () => {
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [searchkey, setSearchkey] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <AppErrorBoundary>
      {/* <OccasionsHeader /> */}
      <OccasionsContent
        isLoaderOpen={isLoaderOpen}
        setIsLoaderOpen={setIsLoaderOpen}
        searchkey={searchkey}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </AppErrorBoundary>
  );
};

export default OccasionsContainer;
