import HomePage from '../pages/HomePage';
import LoginPage from '../pages/LoginPage';
import OccasionsPage from '../pages/OccasionsPage';
import AddOccasionPage from '../pages/AddOccasionPage';
import EditOccasionPage from '../pages/EditOccasionPage';
import AttendancePage from '../pages/AttendancePage';
import CoversPage from '../pages/CoversPage';
import ApologizePage from '../pages/ApologizePage';
import OccasionLivePage from '../pages/OccasionLivePage';
import GreetingsPage from '../pages/GreetingsPage';
import CoversLivePage from '../pages/CoversLivePage';
//guards
import PrivateRouteGuard from './guards/PrivateRouteGuard';
import PublicRouteGuard from './guards/PublicRouteGuard';

export const routes = [
  {
    element: (
      <PublicRouteGuard redirect="/home">
        <HomePage />
      </PublicRouteGuard>
    ),
    path: '/',
  },
  {
    element: (
      <PublicRouteGuard>
        <LoginPage />
      </PublicRouteGuard>
    ),
    path: '/login',
  },
  {
    element: (
      <PrivateRouteGuard>
        <HomePage />
      </PrivateRouteGuard>
    ),
    path: '/home',
  },
  {
    element: (
      <PrivateRouteGuard>
        <OccasionsPage />
      </PrivateRouteGuard>
    ),
    path: '/occasions',
  },
  {
    element: (
      <PrivateRouteGuard>
        <AddOccasionPage />
      </PrivateRouteGuard>
    ),
    path: '/add-occasion',
  },
  {
    element: (
      <PrivateRouteGuard>
        <EditOccasionPage />
      </PrivateRouteGuard>
    ),
    path: '/edit-occasion/:id',
  },
  {
    element: (
      <PrivateRouteGuard>
        <AttendancePage />
      </PrivateRouteGuard>
    ),
    path: '/attendance',
  },
  {
    element: (
      <PrivateRouteGuard>
        <CoversPage />
      </PrivateRouteGuard>
    ),
    path: '/covers',
  },
  {
    element: (
      <PublicRouteGuard>
        <ApologizePage />
      </PublicRouteGuard>
    ),
    path: '/apologize/:id',
  },
  {
    element: (
      <PublicRouteGuard>
        <OccasionLivePage />
      </PublicRouteGuard>
    ),
    path: '/occasion-live/:id',
  },
  {
    element: (
      <PrivateRouteGuard>
        <GreetingsPage />
      </PrivateRouteGuard>
    ),
    path: '/greetings',
  },
  {
    element: (
      <PublicRouteGuard>
        <CoversLivePage />
      </PublicRouteGuard>
    ),
    path: '/covers-live/:id',
  },
  {
    element: <p>Page NOT found</p>,
    path: '*',
  },
];
