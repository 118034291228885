import React from 'react';
// components
import LoadingIcon from './LoadingIcon';

const ProcessingLoader = ({ height = 50 }) => (
  <div style={{ height }} className="modal-loader-wrapper">
    <LoadingIcon />
  </div>
);
export default ProcessingLoader;
