import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SectionLoader from '../components/shared/SectionLoader';
import AppErrorBoundary from './../components/shared/AppErrorBoundary';
import {
  dateFormatDDMMYYYY,
  flagIcon,
  formatCustomDateTime,
  coverNotedEnum,
  timeFormatHHMM,
} from './../constants/Helpers';
//material ui
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import MaterialDataGrid from './../components/shared/MaterialDataGrid';
import { Button } from '@material-ui/core';
//API
import AppService from '../services/AppService';

const CoversLivePage = () => {
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [occasionData, setOccasionData] = useState({});
  const [clonedOccasionData, setClonedOccasionData] = useState({});
  const [coversData, setCoversData] = useState([]);
  const params = useParams();

  const phoneCoverStatusIcons = {
    0: <img src={flagIcon.falseIcon} style={{ width: 30 }} />,
    1: (
      <img
        src="https://imagecdn.prod.floward.com/web/files/tickets/79d6520e-258a-477c-ab34-92ffa0881033.jpg"
        style={{ width: 90 }}
      />
    ),
    2: (
      <>
        <img
          src="https://imagecdn.prod.floward.com/web/files/tickets/55c6b8f5-1d9d-4dfc-96c2-bdc7d498cfcd.jpg"
          style={{ width: 90 }}
        />
      </>
    ),
  };

  const fetchUserData = async (ownerToken) => {
    try {
      const res = await AppService.getDataByOwnerToken(ownerToken);
      setOccasionData(res?.data);
      setClonedOccasionData(res?.data);
      setIsLoaderOpen(false);
    } catch (err) {}
  };

  const fetchCoversData = async (ownerToken) => {
    setIsLoaderOpen(true);
    try {
      const res = await AppService.getFetchCoversByToken(ownerToken);
      setCoversData(res?.data);
      setIsLoaderOpen(false);
    } catch (err) {
      setIsLoaderOpen(false);
    }
  };

  useEffect(() => {
    fetchUserData(params?.id);
    fetchCoversData(params?.id);
  }, []);

  const columns = [
    {
      title: 'اسم المدعو',
      render: (rowData) => <span>{rowData?.invitee?.name}</span>,
    },
    {
      title: 'رقم التليفون',
      render: (rowData) => <span>{rowData?.invitee?.phone}</span>,
    },
    // {
    //   title: 'رقم الكڤر',
    //   field: 'coverNumber',
    //   render: (rowData) => (
    //     <span>
    //       {rowData?.coverNumber || <img src={flagIcon.falseIcon} style={{ width: 30 }} />}
    //     </span>
    //   ),
    // },
    {
      title: 'الحالة',
      render: (rowData) => <span>{phoneCoverStatusIcons[rowData?.phoneCoverStatus] || ''}</span>,
    },
    {
      title: 'وقت الدخول',
      field: 'checkedInDate',
      render: (rowData) => (
        <span>
          {rowData?.checkedInDate ? (
            timeFormatHHMM(rowData?.checkedInDate)
          ) : (
            <img src={flagIcon.falseIcon} style={{ width: 30 }} />
          )}
        </span>
      ),
    },
    {
      title: 'وقت الخروج',
      field: 'checkedOutDate',
      render: (rowData) => (
        <span>
          {rowData?.checkedOutDate ? (
            timeFormatHHMM(rowData?.checkedOutDate)
          ) : (
            <img src={flagIcon.falseIcon} style={{ width: 30 }} />
          )}
        </span>
      ),
    },
    {
      title: 'ملاحظات',
      render: (rowData) => (
        <p style={{ color: 'gray' }}>
          {rowData?.note === -1 ? 'لا يوجد' : coverNotedEnum[rowData?.note]}
        </p>
      ),
    },
  ];

  const options = {
    paging: false,
    showTitle: false,
    columnsButton: false,
    sorting: false,
    exportButton: false,
    toolbar: false,
  };

  const getAttendeceCount = () => {
    const attendanceEntries =
      clonedOccasionData?.invitees
        ?.filter((el) => el?.isAttended === true)
        ?.flatMap((entry) => entry?.qrCodes) ?? [];
    const totalLength = attendanceEntries?.filter((invitee) => invitee?.isQrCodeUsed)?.length;
    return totalLength;
  };

  return (
    <div className="apologize-container">
      {isLoaderOpen ? (
        <SectionLoader />
      ) : (
        <div className="apologize-wrapper">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ alignSelf: 'start', margin: 15, textAlign: 'right' }}>
              <img
                className="header-ops-img"
                src={
                  'https://floward.imgix.net/web/files/tickets/8701f83f-b7f6-4b01-b9ac-38afdce5f521.png'
                }
                style={{ width: 100 }}
              />
            </div>
          </div>
          <div>
            <p style={{ fontWeight: 'bold', color: '#1E3F66' }}>{occasionData?.name}</p>
            <p style={{ fontWeight: 'bold', color: '#1E3F66' }}>
              {dateFormatDDMMYYYY(occasionData?.date)}
            </p>
            <Button
              style={{ backgroundColor: '#5cb85c', color: 'white', margin: 5 }}
              variant="contained"
              type="submit"
            >
              اجمالي الكڤرات : {getAttendeceCount()}
            </Button>
            <AppErrorBoundary>
              <TableContainer component={Paper}>
                <MaterialDataGrid data={coversData ?? []} columns={columns} options={options} />
              </TableContainer>
            </AppErrorBoundary>
          </div>
        </div>
      )}
    </div>
  );
};

export default CoversLivePage;
