import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import { toast } from 'react-toastify';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  Typography,
  DialogContent,
  DialogActions,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
// import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import SectionLoader from '../shared/SectionLoader';
import {
  excelToJSON,
  isRowValid,
  checkUserType,
  checkVehicleType,
  capitalize,
  formatDrivers,
} from '../../constants/Helpers';
//Selector
import { getIsBulkUploadDriversModalOpen, getOpsCenterInfo } from '../../store/app/AppSelectors';
//Actions
import { setToggleBulkUploadDriversModalOpen } from '../../store/app/AppActions';
//Services
import DriversService from '../../services/DriversService';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(3),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const BulkUploadDriversModal = () => {
  const [dataTable, setDataTable] = useState([]);
  const [isValidList, setIsValidList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);
  const [uploadingData, setUploadingData] = useState(false);
  const [progressChunk, setPrgressChunk] = useState(0);
  const [processMessage, setProcessMessage] = useState('');
  const [processResType, setProcessResType] = useState('success');
  const [existingDriverPhones, setExistingDriverPhones] = useState([]);
  const [existingDriverEmails, setExistingDriverEmails] = useState([]);
  const isBulkUploadDriversModalOpen = useSelector((state) =>
    getIsBulkUploadDriversModalOpen({ state })
  );
  const vehicleTypesList = useSelector((state) => getVehicleListTypes({ state }));
  const opsInfo = useSelector((state) => getOpsCenterInfo({ state }));
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(setToggleBulkUploadDriversModalOpen());
  };

  const checkValid = (validList, data) => {
    const newValidList = cloneDeep(validList);
    const numberReg = /^[0-9]+$/;
    const emailReg = /@floward.com$/;
    data.forEach((row, rowInd) => {
      if (rowInd !== 0) {
        row.forEach((cell, cellInd) => {
          switch (cellInd) {
            case 0:
              if (cell === '') {
                newValidList[rowInd][cellInd] = false;
                setDisableBtn(true);
              }
              break;
            case 1:
              if (cell === '' || !numberReg.test(cell)) {
                newValidList[rowInd][cellInd] = false;
                setDisableBtn(true);
              }
              break;
            case 2:
              if (cell === '' || !checkUserType(cell)) {
                newValidList[rowInd][cellInd] = false;
                setDisableBtn(true);
              }
              break;
            case 3:
              if (cell === '' || !checkVehicleType(cell, vehicleTypesList)) {
                newValidList[rowInd][cellInd] = false;
                setDisableBtn(true);
              }
              break;
            case 4:
              if (
                !(capitalize(row[2]) == 'Insource' && emailReg.test(cell)) &&
                !(capitalize(row[2]) != 'Insource' && cell == '')
              ) {
                newValidList[rowInd][cellInd] = false;
                setDisableBtn(true);
              }
              break;
            default:
              break;
          }
        });
      }
    });
    setIsValidList(newValidList);
  };

  const validationMessages = {
    0: "This field is required and can't be empty",
    1: "This field is required, can't be empty and it should contain numbers only",
    2: "This field is required, it should be either 'Insource', 'Outsouce', or 'Freelance'",
    3: "This field is required, it should be either 'Van', 'Car', 'Jeep' or 'Truck'",
    4: "This field is required if user type is 'Insource' otherwise should empty, it should end with '@floward.com'",
  };

  const clearAfterSuccess = (msg, haveSuccessChunck) => {
    setIsValidList([]);
    setDataTable([]);
    if (haveSuccessChunck) {
      if (msg.length > 0) {
        setProcessResType('warning');
        setProcessMessage(
          `Drivers have been created successfully. Some drivers failed to be created, please check the uploaded file (${msg.join(
            '<br>'
          )})`
        );
      } else {
        setProcessResType('success');
        setProcessMessage('Drivers have been created successfully.');
      }
    } else {
      setProcessResType('error');
      setProcessMessage(
        `Drivers failed to be created, please check the uploaded file (${msg.join('<br>')})`
      );
    }
  };

  const handleSubmit = async () => {
    const failedMsg = [];
    let haveSuccessChunck = false;
    const chunkSize = 10;
    let chunkProg = 0;
    let lastInd = 1;
    const chunks = dataTable
      .map((dT, ind) => {
        if (ind !== 0 && ind % chunkSize === 0) {
          lastInd = ind + 1;
          return dataTable.slice(ind - chunkSize + 1, ind + 1);
        } else {
          if (ind === dataTable.length - 1 && lastInd !== ind) {
            return dataTable.slice(lastInd, ind + 1);
          } else {
            return null;
          }
        }
      })
      .filter((dT) => dT);

    setLoading(true);
    setUploadingData(true);
    for (const [index, c] of chunks.entries()) {
      const data = {
        drivers: formatDrivers(c, vehicleTypesList, opsInfo.Id),
      };
      let count = 0;
      const maxTries = 2;
      let keepTrying = true;
      while (keepTrying) {
        try {
          const res = await DriversService.bulkUploadDrivers(data);
          setExistingDriverPhones((prev) => [...prev, ...res.data.existing_driver_phones]);
          setExistingDriverEmails((prev) => [...prev, ...res.data.existing_driver_emails]);
          chunkProg += c.length;
          setPrgressChunk(chunkProg);
          keepTrying = false;
          haveSuccessChunck = true;
          if (index + 1 === chunks.length) {
            clearAfterSuccess(failedMsg, haveSuccessChunck);
            setLoading(false);
          }
        } catch (e) {
          if (++count === maxTries) {
            keepTrying = false;
            const failMessage = `Data from line ${chunkProg + 2} to line ${
              chunkProg + c.length + 1
            } has failed after 2 tries!`;
            toast.error(failMessage);
            failedMsg.push(failMessage);
            chunkProg += c.length;
            setPrgressChunk(chunkProg);
            if (index + 1 === chunks.length) {
              clearAfterSuccess(failedMsg, haveSuccessChunck);
              setLoading(false);
            }
          }
        }
      }
    }
  };

  const formatArray = (array) => {
    const result = array.map((element, index) => {
      return index == array.length - 1 ? element : element + ', ';
    });
    return result;
  };

  return (
    <Dialog
      onClose={handleCloseModal}
      aria-labelledby="customized-dialog-title"
      open={isBulkUploadDriversModalOpen}
      style={{ height: 800 }}
      maxWidth="lg"
    >
      <DialogTitle id="customized-dialog-title" onClose={handleCloseModal}>
        Bulk Upload Drivers
      </DialogTitle>
      <DialogContent dividers className="bulk-upload-drivers">
        <div className="file-input-wrapper">
          <label className="file-input-label" htmlFor="excel-create-order">
            Upload Excel (
            <a
              className="template-download-link"
              href="https://floward.s3.eu-west-2.amazonaws.com/web/BulkUploadDriversTemplate.xlsx"
              title="Download bulk drivers template"
            >
              download template
            </a>
            )
          </label>
          <input
            id="excel-create-order"
            onChange={(e) =>
              excelToJSON(
                e.target.files[0],
                setDataTable,
                setIsValidList,
                checkValid,
                setDisableBtn,
                setLoading,
                setProcessMessage,
                setPrgressChunk,
                setExistingDriverPhones,
                setExistingDriverEmails
              )
            }
            onClick={(e) => (e.target.value = null)}
            className="file-input"
            type="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
        </div>

        {/* {processMessage !== '' && (
          <Alert severity={processResType} variant="filled" className="alert-bar">
            <p dangerouslySetInnerHTML={{ __html: processMessage }}></p>
          </Alert>
        )}

        {(existingDriverPhones.length > 0 || existingDriverEmails.length > 0) && !loading && (
          <Alert severity="warning" variant="filled" className="alert-bar">
            {existingDriverPhones.length > 0 && (
              <p>
                Drivers with the following phone numbers already exists:{' '}
                {formatArray(existingDriverPhones)}
              </p>
            )}
            {existingDriverEmails.length > 0 && (
              <p>
                Drivers with the following emails already exists:{' '}
                {formatArray(existingDriverPhones)}
              </p>
            )}
          </Alert>
        )} */}

        {loading && (
          <>
            {uploadingData && (
              <div>
                <Alert severity="warning" variant="filled" className="alert-bar">
                  Please don&apos;t close or refresh this window until the process is finished.
                </Alert>
                <p className="progress-indicator">
                  {progressChunk}/{dataTable.length - 1}
                </p>
              </div>
            )}
            <div className="loader-wrapper">
              <SectionLoader />
            </div>
          </>
        )}

        {dataTable.length > 0 && !loading && (
          <div className="bulk-orders-list">
            {disableBtn && (
              <p className="error-msg">
                <ErrorIcon fontSize="small" />
                If any row was highlighted with red, hover over the row&apos;s cells to check which
                one is causing the error
              </p>
            )}
            <table className="m-b-0 full-width">
              <thead
                className="align-center"
                style={{ borderBottom: '1px solid black', textTransform: 'capitalize' }}
              >
                <tr>
                  {dataTable[0].map((head, headInd) => {
                    return (
                      <th style={{ height: 50 }} key={headInd}>
                        {head}
                      </th>
                    );
                  })}
                </tr>
              </thead>
            </table>
            <div className="confirmation-table-scroll">
              <table className="m-b-0 full-width">
                <tbody className="align-center">
                  {dataTable.map((row, rowInd) => {
                    if (rowInd !== 0) {
                      return (
                        <tr
                          style={{
                            height: 40,
                            backgroundColor: !isRowValid(isValidList[rowInd])
                              ? 'rgba(255, 142, 142, 0.62)'
                              : 'white',
                          }}
                          key={rowInd}
                        >
                          {row?.map((cell, cellInd) => {
                            if (isValidList[rowInd][cellInd]) {
                              return <td key={cellInd}>{cell}</td>;
                            } else {
                              return (
                                <Tooltip title={validationMessages[cellInd] || ''} key={cellInd}>
                                  <td>{cell}</td>
                                </Tooltip>
                              );
                            }
                          })}
                        </tr>
                      );
                    } else {
                      return <tr key={rowInd}></tr>;
                    }
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions style={{ padding: '8px 24px' }}>
        <Button
          autoFocus
          onClick={handleSubmit}
          color="primary"
          disabled={disableBtn || dataTable.length < 3}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BulkUploadDriversModal;
