import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Grid from '@material-ui/core/Grid';
import { toast } from 'react-toastify';
//api
import AppService from '../services/AppService';

const LoginPage = () => {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [disableBtn, setDisableBtn] = useState(false);
  const dispatch = useDispatch();

  const handleLogin = async () => {
    let dataBody = {
      userName,
      password,
    };
    setDisableBtn(true);
    try {
      const res = await AppService.getLogin(dataBody);
      toast.success(res?.data?.message ?? 'تم الدخول بنجاح');
      dispatch({
        type: 'SET_IS_LOGGED_IN_TRUE',
        payload: res?.data,
      });
      if (res?.data?.type == 1) window.location.href = '/home';
      else window.location.href = '/attendance';

      setDisableBtn(false);
    } catch (e) {
      toast.error(e?.response?.data?.message ?? 'خطأ في الاسم او كلمة المرور');
      setDisableBtn(false);
    }
  };

  return (
    <Grid className="login-page-wrapper add-driver-modal" container>
      <Grid className="login-form-block" xs={12} md={6}>
        <h2>مرحبا في دعوة</h2>
        <p>من فضلك سجل دخولك</p>
        <Grid item md={4}>
          <div className="input-wrapper">
            <input
              onChange={(e) => setUserName(e.target.value)}
              className="input"
              type="text"
              value={userName}
              placeholder="الاسم"
            />
          </div>
        </Grid>
        <Grid item md={4}>
          <div className="input-wrapper">
            <input
              onChange={(e) => setPassword(e.target.value)}
              className="input"
              type="password"
              value={password}
              placeholder="كلمة المرور"
            />
          </div>
        </Grid>
        <Button
          className="login-btn"
          endIcon={<ExitToAppIcon style={{ marginRight: 10 }} />}
          variant="contained"
          color="success"
          onClick={handleLogin}
          disabled={disableBtn || !userName || !password}
        >
          تسجيل الدخول
        </Button>
      </Grid>
      <Grid className="cover-img-wrapper" xs={12} md={6}></Grid>
    </Grid>
  );
};

export default LoginPage;
