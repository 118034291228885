import { apiLogisticsService, apiGatewayService } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/EndPoints';

class DriversService {
  static getDriversList(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getDriversList, ...extraParams),
      params: data,
    });
  }

  static getDriverInfoById(...extraParams) {
    return apiLogisticsService({
      method: 'GET',
      url: setEndPoint(endPoints.getDriverInfoById, ...extraParams),
    });
  }

  static getDriverOrdersList(...extraParams) {
    return apiLogisticsService({
      method: 'GET',
      url: setEndPoint(endPoints.getDriverOrdersList, ...extraParams),
    });
  }

  static getOrderDetailsInfo(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getOrderDetailsInfo, ...extraParams),
    });
  }

  static getOrderCartDetails(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getOrderCartDetails, ...extraParams),
    });
  }

  static getAllDriversList(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.driverData, ...extraParams),
      params: data,
    });
  }
  static addDriverData(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.driverData, ...extraParams),
      data,
    });
  }

  static getDriversVehiclesTypes(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getDriversVehiclesTypes, ...extraParams),
    });
  }
  static bulkUploadDrivers(data, ...extraParams) {
    return apiGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.bulkUploadDrivers, ...extraParams),
      data,
    });
  }
}

export default DriversService;
