import React from 'react';
import AttendanceContainer from '../components/attendance/AttendanceContainer';

const AttendancePage = () => {
  return (
    <div className="page-wrapper">
      <AttendanceContainer />
    </div>
  );
};

export default AttendancePage;
