import React from 'react';

const SectionLoader = () => {
  return (
    <div className="loaderContainer">
      <div className="loader"></div>
    </div>
  );
};

export default SectionLoader;
