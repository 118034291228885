import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Button, Dialog, DialogActions, Divider } from '@material-ui/core';
import { flagIcon } from '../../constants/Helpers';
import ProcessingLoader from '../shared/ProcessingLoader';
import AppService from '../../services/AppService';

const DeleteInviteeModal = ({
  selectedInv,
  isDeletePopupOpen,
  setIsDeletePopupOpen,
  occasionId,
  fetchOccasionById,
  setSelectedInv,
}) => {
  const [isLoading, setIsLoading] = useState('');

  const handleSubmit = async () => {
    let param = {
      occasionId,
    };
    setIsLoading(true);
    try {
      const res = await AppService.deleteInvitee(param, selectedInv?.id);
      toast.success(res?.data?.message ?? 'تم حذف الدعوه بنجاح');
      setTimeout(() => {
        fetchOccasionById(occasionId);
      }, 1000);
      setIsDeletePopupOpen(false);
      setIsLoading(false);
    } catch (e) {
      toast.error(e?.response?.data?.message ?? 'فشل في حذف الدعوه');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      setSelectedInv('');
    };
  }, []);

  return (
    <div className="">
      <Dialog
        open={isDeletePopupOpen}
        onClose={() => setIsDeletePopupOpen((prev) => !prev)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {isLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <ProcessingLoader /> <span>جاري الحذف ...</span>
          </div>
        ) : (
          <>
            <div
              style={{
                padding: 30,
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img src={flagIcon.falseIcon} style={{ width: 30, marginRight: 5 }} />
              تأكيد حذف الدعوات؟
            </div>
            <Divider />
          </>
        )}
        <DialogActions
          style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}
        >
          <Button
            variant="contained"
            type="submit"
            onClick={() => setIsDeletePopupOpen(false)}
            color="default"
          >
            الغاء
          </Button>
          <Button
            style={{ backgroundColor: '#006782', color: 'white', margin: 5 }}
            variant="contained"
            type="submit"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            تأكيد
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteInviteeModal;
