import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import AppErrorBoundary from '../shared/AppErrorBoundary';
import CustomSelect from '../shared/CustomSelect';
import { Card, CardContent, Grid, TextField } from '@material-ui/core';
//material ui
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: theme.mixins.toolbar,
  search: {
    border: '1px solid gray',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    margin: '5px 0',
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      '&:focus': {
        width: '100%',
      },
    },
  },
}));

const OccasionsHeader = () => {
  const [name, setName] = useState('');
  const [active, setActive] = useState(0);
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [disableBtn, setDisableBtn] = useState(false);
  const dispatch = useDispatch();

  return (
    <Card className="card-wrapper">
      <CardContent>
        <div className="add-driver-modal">
          <form className="form">
            <Grid container>
              <Grid item md={3} sm={12}>
                <div className="input-wrapper">
                  <label htmlFor="driver-name" className="input-label">
                    الاسم
                  </label>
                  <input
                    id="driver-name"
                    onChange={(e) => setName(e.target.value)}
                    className="input"
                    type="text"
                    value={name}
                    placeholder="ابحث اسم الدعوة"
                  />
                </div>
              </Grid>
              <Grid item md={3} sm={12}>
                <div className="input-wrapper select-input">
                  <label htmlFor="driver-type" className="input-label">
                    فعال
                  </label>
                  <AppErrorBoundary>
                    <CustomSelect
                      listData={[
                        { id: 0, name: 'الكل' },
                        { id: 1, name: 'نعم' },
                        { id: 2, name: 'لا' },
                      ]}
                      handleChange={(value) => setActive(value?.id)}
                      placeholder="Select a type"
                      selectedValue={active}
                      optionValue="id"
                      optionName="name"
                      disableClearIcon
                    />
                  </AppErrorBoundary>
                </div>
              </Grid>
              <Grid item md={3} sm={12}>
                <div className="input-wrapper">
                  <label className="input-label" htmlFor="driver-sdate">
                    تاريخ من
                  </label>
                  <TextField
                    id="driver-sdate"
                    variant="outlined"
                    type="date"
                    className="date-picker"
                    value={dateFrom}
                    onChange={(e) => setDateFrom(e.target.value)}
                  />
                </div>
              </Grid>
              <Grid item md={3} sm={12}>
                <div className="input-wrapper">
                  <label className="input-label" htmlFor="driver-sdate">
                    تاريخ الي
                  </label>
                  <TextField
                    id="driver-sdate"
                    variant="outlined"
                    type="date"
                    className="date-picker"
                    value={dateTo}
                    onChange={(e) => setDateTo(e.target.value)}
                  />
                </div>
              </Grid>
            </Grid>
          </form>
        </div>
      </CardContent>
    </Card>
  );
};

export default OccasionsHeader;
