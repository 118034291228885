//api
import { TRUE } from 'sass';
import AppService from '../../services/AppService';

export const setIsLoggedInTrue = () => ({ type: 'SET_IS_LOGGED_IN_TRUE' });

export const setIsLoggedInFalse = () => ({ type: 'SET_IS_LOGGED_IN_FALSE' });

export const setToggleDarkLightMode = () => ({
  type: 'TOGGLE_DARK_LIGHT_MODE',
});

export const setToggleLeftSideBar = () => ({
  type: 'TOGGLE_LEFT_SIDE_BAR',
});

export const setToggleRightSideBar = () => ({
  type: 'TOGGLE_RIGHT_SIDE_BAR',
});

export const setToggleAssignOrdersModalOpen = () => ({
  type: 'TOGGLE_ASSIGN_ORDERS_MODAL_OPEN',
});

export const setToggleAddDriverModalOpen = () => ({
  type: 'TOGGLE_ADD_DRIVER_MODAL_OPEN',
});

export const setToggleCreatePlanModalOpen = () => ({
  type: 'TOGGLE_CREATE_PLAN_MODAL_OPEN',
});

export const setTogglePlannedOrdersModalOpen = () => ({
  type: 'TOGGLE_PLANNED_ORDERS_MODAL_OPEN',
});

export const setToggleBulkUploadDriversModalOpen = () => ({
  type: 'TOGGLE_BULK_UPLOAD_DRIVERS_MODAL_OPEN',
});

export const setOpsInfo = () => async (dispatch) => {
  const opsId = localStorage.getItem('opsId');
  try {
    const res = await AppService.getOpsCenterInfo(opsId);
    dispatch({
      type: 'SET_OPS_CENTER_INFO',
      payload: res.data,
    });
  } catch (err) {}
};

export const setToggleOpsModalOpen = () => ({
  type: 'TOGGLE_OPS_MODAL_OPEN',
});

export const setAllOpsCenters = () => async (dispatch) => {
  try {
    const res = await AppService.getAllOpsCenter({ allops: true });
    dispatch({
      type: 'SET_ALL_OPS_CENTERS',
      payload: res.data,
    });
  } catch (e) {}
};

export const setResetDefaultZoom = () => ({
  type: 'SET_RESET_DEFAULT_ZOOM',
});

export const setIncreaseDefaultZoom = () => ({
  type: 'SET_INCREASE_DEFAULT_ZOOM',
});

export const setDecreaseDefaultZoom = () => ({
  type: 'SET_DECREASE_DEFAULT_ZOOM',
});

export const setToggleDriversListModal = () => ({
  type: 'TOGGLE_DRIVERS_LIST_MODAL',
});

//DAWA

export const setIsLoaderOpenTrue = () => ({ type: 'SET_IS_LOADER_OPEN_TRUE' });

export const setIsLoaderOpenFalse = () => ({ type: 'SET_IS_LOADER_OPEN_FALSE' });

export const setOccasionsList = () => async (dispatch) => {
  dispatch(setIsLoaderOpenTrue());
  try {
    const res = await AppService.getOccasionsList();
    dispatch({
      type: 'SET_OCCASIONS_LIST',
      payload: res.data,
    });
    dispatch(setIsLoaderOpenFalse());
  } catch (err) {
    dispatch(setIsLoaderOpenFalse());
  }
};

export const setSelectedOccasion = (occasion) => ({
  type: 'SET_SELECTED_OCCASION',
  payload: occasion,
});

export const setWebSocketConnection = (occasionId) => async (dispatch) => {
  let newSocket;

  const connectWebSocket = () => {
    newSocket = new WebSocket(
      `wss://dawa-be.azurewebsites.net/api/Occasion/notifications/subscribe?occasionId=${occasionId}`
    );

    newSocket.onopen = (event) => {
      console.log('WebSocket connection opened:', event);
    };

    newSocket.onmessage = (event) => {
      const data = JSON.parse(event?.data);
      dispatch({
        type: 'SET_NEW_RECEIVED_NOTIFICATION',
        payload: data,
      });
      dispatch({
        type: 'SET_IS_NEW_NOTIFICATION_TRUE',
      });
    };

    newSocket.onclose = (event) => {
      connectWebSocket();
    };

    newSocket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
  };
  connectWebSocket();
};

export const setCoverWebSocketConnection = (occasionId) => async (dispatch) => {
  let newSocket;

  const connectWebSocket = () => {
    newSocket = new WebSocket(
      `wss://dawa-be.azurewebsites.net/api/Occasion/notifications/covers/subscribe?occasionId=${occasionId}`
    );

    newSocket.onopen = (event) => {
      console.log('WebSocket connection opened:', event);
    };

    newSocket.onmessage = (event) => {
      const data = JSON.parse(event?.data);
      dispatch({
        type: 'SET_NEW_SCANNED_INVITEE',
        payload: data,
      });
    };

    newSocket.onclose = (event) => {
      connectWebSocket();
    };

    newSocket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
  };
  connectWebSocket();
};
