import React from 'react';
import CoversContainer from '../components/covers/CoversContainer';

const CoversPage = () => {
  return (
    <div className="page-wrapper">
      <CoversContainer />
    </div>
  );
};

export default CoversPage;
