import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { dropdownOptionStyle } from '../../constants/Helpers';

const CustomSelect = ({
  listData = [],
  optionName,
  optionValue,
  selectedValue,
  handleChange,
  isDisabled,
  placeholder,
  customProp,
  disableClearIcon,
  onOpen,
  disabledOption = [],
}) => {
  const selectedOptionStyle = {
    ...dropdownOptionStyle,
    color: '#0c697e',
    backgroundColor: '#edeef0',
  };

  const renderOption = (props, option, state) => (
    <li {...props} style={state.selected ? selectedOptionStyle : dropdownOptionStyle}>
      {option[optionName] ?? ''}
    </li>
  );

  return (
    <div className="custom-border">
      <Autocomplete
        id="auto-complete"
        options={listData} //array of displayed list
        getOptionLabel={(option) => option[optionName] ?? ''} //the string value for a given option
        value={listData.find((el) => el[optionValue] === selectedValue) || null} // passing null to prevent the Autocomplete going into uncontrolled mode
        onChange={(e, value) => handleChange(value)} //callback fires when the value changes
        autoComplete
        {...customProp}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{ input: { fontSize: '13px', cursor: 'pointer' } }}
            placeholder={placeholder}
            variant="standard"
          />
        )}
        disabled={isDisabled}
        renderOption={renderOption}
        disableClearable={customProp?.disableClearable ?? disableClearIcon}
        onOpen={onOpen}
        getOptionDisabled={(option) => disabledOption?.includes(option[optionValue])}
      />
    </div>
  );
};

export default CustomSelect;
