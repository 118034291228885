import React from 'react';
//components
import OccasionsContainer from '../components/Occasions/OccasionsContainer';
import AppErrorBoundary from '../components/shared/AppErrorBoundary';

const OccasionsPage = () => {
  return (
    <div className="page-wrapper">
      <AppErrorBoundary>
        <OccasionsContainer />
      </AppErrorBoundary>
    </div>
  );
};

export default OccasionsPage;
