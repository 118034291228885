import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
//material ui
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
//constants
import { handleRealoadPage } from '../../constants/Helpers';
//icons
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import NightsStayIcon from '@material-ui/icons/NightsStay';
import ReplayIcon from '@material-ui/icons/Replay';
//actions
import {
  setToggleDarkLightMode,
  setToggleRightSideBar,
  setToggleLeftSideBar,
} from '../../store/app/AppActions';
//selectors
import { getIsDarkMode, getIsMobileSize } from '../../store/app/AppSelectors';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    // marginRight: theme.spacing(2),
    zIndex: 2001,
  },
  title: {
    flexGrow: window.screen.width < 500 ? 0 : 1,
    fontFamily: 'sans-serif',
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(2),
    padding: '4px 10px 4px 4px',
    fontSize: 11,
  },
}));

const Navbar = () => {
  const classes = useStyles(),
    isDarkMode = useSelector((state) => getIsDarkMode({ state })),
    isMobile = useSelector((state) => getIsMobileSize({ state })),
    // isLoggedIn = useSelector((state) => getIsLoggedIn({ state })),
    isLoggedIn = true,
    dispatch = useDispatch();
  const location = useLocation();

  return (
    <div className={classes.root} style={{ display: isLoggedIn ? '' : 'none' }}>
      <AppBar style={{ backgroundColor: isDarkMode ? '#333' : '#006782' }} position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={() => dispatch(setToggleRightSideBar())}
            style={{ visibility: 'hidden' }}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.title}>
            <img
              onClick={() => (window.location.href = '/home')}
              className="header-ops-img"
              src={
                'https://floward.imgix.net/web/files/tickets/8701f83f-b7f6-4b01-b9ac-38afdce5f521.png'
              }
            />

            <IconButton
              edge="middle"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={() => dispatch(setToggleRightSideBar())}
            >
              <MenuIcon />
            </IconButton>
          </div>
          <ReplayIcon onClick={handleRealoadPage} className="reload-icon" />
          <FormGroup style={{ display: isMobile ? 'none' : '', marginLeft: '16px' }}>
            <FormControlLabel
              control={
                <Switch
                  style={{ zIndex: 2001 }}
                  checked={isDarkMode}
                  onChange={() => dispatch(setToggleDarkLightMode())}
                  aria-label="login switch"
                />
              }
              label={isDarkMode ? <NightsStayIcon /> : <WbSunnyIcon />}
            />
          </FormGroup>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Navbar;
